<template>
  <div id="vl-bottom-actions">
    <div>

      <div id="vl-bottom-actions-menu" class="absolute space-y-14p z-10">
        <div id="all-documents" class="opacity-100 bg-blue-dark py-8p px-16p rounded-full text-white select-none" @click="$store.dispatch('DossierModule/selectAllDossiers')">Selecteer alle documenten</div>
        <div id="add-action" class="opacity-0 bg-blue-dark py-8p px-16p rounded-full text-white select-none" @click="addAction">Voeg een actie toe</div>
        <div id="generate-template" class="opacity-0 bg-blue-dark py-8p px-16p rounded-full text-white select-none" @click="addTemplate">Genereer templates</div>
      </div>

      <div @click="toggleMenu" class="relative z-20">
        <div class="text-center text-white select-none">
          <font-awesome-icon icon="bars"></font-awesome-icon>
        </div>
        <div class="text-white text-center select-none">acties</div>
      </div>

    </div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "VlBottomActionsButton",
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    addAction() {
      this.$emit('show-action-modal')
      this.toggleMenu()
    },
    addTemplate(){
      this.$emit('show-templates-modal')
      this.toggleMenu()
    },
    toggleMenu() {
      if (!this.showMenu) this.openAnimation()
      if (this.showMenu) this.closeAnimation()
    },
    openAnimation() {
      const timeline = gsap.timeline()
      timeline.fromTo('#vl-bottom-actions-menu', {y: 0}, {y: '-180px', opacity: 1, duration: 0.3  })
      timeline.fromTo('#all-documents', {y: '160px'}, {y: 0, x: '16px', opacity: 1, duration: 0.3  }, '-=0.3')
      timeline.fromTo('#add-action', {y: '160px'}, {y: 0 ,x: '16px', opacity: 1, duration: 0.3  }, '-=0.2')
      timeline.fromTo('#generate-template', {y: '160px'}, {y: 0,x: '16px', opacity: 1, duration: 0.3}, '-=0.2')
      timeline.play().then(() => this.showMenu = true)
    },
    closeAnimation() {
      const timeline = gsap.timeline()
      timeline.fromTo('#generate-template', {y: 0}, {y: '160px',x: 0, opacity: 0, duration: 0.3})
      timeline.fromTo('#add-action', {y: 0}, {y: '160px',x: 0, opacity: 0, duration: 0.3}, '-=0.2')
      timeline.fromTo('#all-documents', {y: 0}, {y: '160px',x: 0, opacity: 0, duration: 0.3},'-=0.2' )
      timeline.fromTo('#vl-bottom-actions-menu', {y: '-180px'}, {y: 0, opacity: 0, duration: 0.3})
      timeline.play().then(() => this.showMenu = false)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
