export const PaginationModule = {
    namespaced: true,
    state: {
        paginated: {
            amount: 25,
            current_page: 1
        }
    },
    getters: {
        getCurrentPage: state => state.paginated.current_page,
        getNumberOfPages: (state, getters, rootState, rootGetters) => {
            return Math.ceil(rootGetters["DossierModule/getTotalFilteredDossiers"] / state.paginated.amount)
        },
        getSliceStart: (state) => (state.paginated.current_page - 1) * state.paginated.amount,
        getSliceEnd: (state, getters) => getters.getSliceStart + state.paginated.amount
    },
    mutations: {
        SET_AMOUNT: (state, payload) => state.paginated.amount = payload,
        SET_PAGE: (state, payload) => state.paginated.current_page = payload
    },
    actions: {
        setAmount: ({commit}, payload) => {
            commit('SET_PAGE', 1)
            commit('SET_AMOUNT', payload)
        },
        setPage: ({commit}, payload) => commit('SET_PAGE', payload),
        addDossiers: ({state, commit}) => {
            commit('SET_AMOUNT', state.paginated.amount += 25)
        }
    },
}
