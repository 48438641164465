<template>
  <div>
    <vl-modal-template>
      <template v-slot:header>
        <vl-modal-header title="Nieuwe actie toevoegen">
          <span class="text-14p font-600 text-blue-light cursor-pointer select-none"
                @click="close">
                <span class="inline-block mr-4p">
            <font-awesome-icon icon="times"/>
          </span>
            Sluiten</span>
        </vl-modal-header>
      </template>

      <template>
        <section>
          <vl-modal-dossier-data
              :active-dossier-number="ActiveDossierNumber"
              :active-dossier-type="ActiveDossierType"
              :active-dossier-last-action="ActiveDossierLastAction"
              :active-dossier-last-action-deadline="ActiveDossierLastActionDeadline"/>
        </section>

        <section class="grid grid-cols-1 xl:grid-cols-2 gap-20p pr-16p lg:pr-0" v-if="HasActiveDossier">
          <section>
            <select-action-type v-model.number="ActionType"></select-action-type>
            <select-execution-date v-model="ExecutionDate"/>
            <select-deadline v-model="Deadline"/>
          </section>

          <section>
            <comment v-model="Comments"/>

            <div class="mb-20p">
              <vl-label label="Wens je bijlagen toe te voegen?"/>

              <div class="grid grid-cols-1fr-min gap-8p">
                <div v-show="HasFiles">
                  <vl-modal-show-file :item="file" :key="`files_${index}`" v-for="(file,index) in Files"
                                      @remove="removeFile(index)"/>
                </div>
                <div>
                  <vl-add-files-button @change="addFiles"/>
                </div>
              </div>
            </div>
          </section>
        </section>
      </template>

      <template v-slot:footer>
        <div class="grid grid-cols-1 gap-16p lg:grid-cols-2" v-if="!see_individual && TotalDossiers !== 0">
          <div>
            <vl-button
                classes="w-full bg-blue-dark hover:bg-blue-dark-hover px-16p py-8p"
                label="Acties individueel bekijken"
                @click="showIndividualFiles"/>
          </div>

          <div class="lg:justify-self-end">
            <button class="w-full bg-blue-dark px-16p py-8p hover:bg-blue-dark-hover rounded-4p"
                    @click="processActionsAsBatch">
             <span class="inline-block text-white mr-4p" v-show="is_uploading">
               <font-awesome-icon icon="circle-notch" class="fa-spin"/>
             </span>
              <span class="text-white">{{
                  is_uploading ? `Acties aan het uitvoeren ${upload_dossier}/${selected_dossiers.length}` : `Uitvoeren op ${selected_dossiers.length} dossiers`
                }}</span>
            </button>
          </div>
        </div>

        <div class="grid individual-actions-grid" v-if="see_individual">
          <div @click="previousDossier" class="text-14p cursor-pointer select-none"
               :class="[active_dossier === 0 ? 'text-gray-light' : 'text-blue-dark']"><span
              class="inline-block text-12p mr-4p"><font-awesome-icon icon="chevron-left"/></span>Vorig dossier
          </div>
          <div class="justify-self-center select-none"><span class="text-14p">{{ CurrentDossier }}/{{
              TotalDossiers
            }}</span></div>
          <div class="justify-self-end">
            <div @click="nextDossier" class=" text-14p cursor-pointer text-blue-dark select-none"
                 v-show="CurrentDossier !== TotalDossiers">Volgend dossier
              <span class="inline-block text-12p ml-4p"><font-awesome-icon icon="chevron-right"/></span></div>

            <div>

              <button class="bg-blue-dark px-16p py-8p hover:bg-blue-dark-hover rounded-4p"
                      @click="processDossiers"
                      v-show="CurrentDossier === TotalDossiers">
             <span class="inline-block text-white mr-4p" v-show="is_uploading">
               <font-awesome-icon icon="circle-notch" class="fa-spin"/>
             </span>
                <span class="text-white">{{
                    is_uploading ? `Acties aan het uitvoeren ${upload_dossier}/${selected_dossiers.length}` : `Uitvoeren op ${selected_dossiers.length} dossiers`
                  }}</span>
              </button>
            </div>
          </div>
        </div>
      </template>

    </vl-modal-template>

  </div>
</template>

<script>

import VlLabel from "@/components/Elements/Form/Elements/VlLabel";
import VlAddFilesButton from "@/components/Elements/Form/Elements/VlAddFilesButton";
import VlButton from "@/components/Elements/VlButton";
import CreateAction from "@/services/models/CreateAction";
import ModalElements from '@/components/Elements/Modal/Elements/ModalElements'
import {ToastMixin} from "@/mixins/ToastMixin";
import * as APIService from '@/services/ApiService'
import {UploadFilesMixin} from "@/mixins/UploadFiles.mixin";

export default {
  name: "VlAddActionsModal",
  components: {
    VlModalTemplate: ModalElements.VlModalTemplate,
    VlModalHeader: ModalElements.VlModalHeader,
    VlModalShowFile: ModalElements.VlModalShowFile,
    VlModalDossierData: ModalElements.VlModalDossierData,
    SelectActionType: ModalElements.VlModalSelectActionType,
    SelectDeadline: ModalElements.VlModalSelectDeadline,
    SelectExecutionDate: ModalElements.VlModalExecutedDate,
    Comment: ModalElements.VlModalComment,
    VlLabel,
    VlAddFilesButton,
    VlButton,
  },
  mixins: [ToastMixin, UploadFilesMixin],
  data() {
    return {
      selected_dossiers: [],
      active_dossier: 0,
      see_individual: false,
      action: {
        action_id: 3640366,
        execution_date: new Date(),
        deadline: null,
        comments: '',
        files: []
      },
      is_uploading: false,
      upload_dossier: 1
    }
  },
  computed: {
    ActionType: {
      get() {
        if (!this.see_individual) {
          return this.action.action_id
        } else {
          return this.ActiveDossier.new_actions.action_id
        }
      },
      set(value) {
        if (!this.see_individual) {
          this.action.action_id = value
        } else {
          this.ActiveDossier.new_actions.action_id = value
        }
      }
    },
    ExecutionDate: {
      get() {
        if (!this.see_individual) {
          return this.action.execution_date
        } else {
          return this.ActiveDossier.new_actions.executed_at
        }

      },
      set(value) {
        if (!this.see_individual) {
          this.action.execution_date = value
        } else {
          this.ActiveDossier.new_actions.executed_at = value
        }
      }
    },
    Deadline: {
      get() {
        if (!this.see_individual) {
          return this.action.deadline
        } else {
          return this.ActiveDossier.new_actions.deadline
        }
      },
      set(value) {
        if (!this.see_individual) {
          this.action.deadline = value
        } else {
          this.ActiveDossier.new_actions.deadline = value
        }
      }
    },
    Comments: {
      get() {
        if (!this.see_individual) {
          return this.action.comments
        } else {
          return this.ActiveDossier.new_actions.comment
        }
      },
      set(value) {
        if (!this.see_individual) {
          this.action.comments = value
        } else {
          this.ActiveDossier.new_actions.comment = value
        }
      }
    },
    Files() {
      {
        if (!this.see_individual) {
          return this.action.files
        }
        return this.ActiveDossier.new_actions.files
      }
    },
    ActiveDossierNumber() {
      if (!this.HasActiveDossier) return ''
      return this.ActiveDossier.examination.dossier_number
    },
    ActiveDossierType() {
      if (!this.HasActiveDossier) return ''
      return this.ActiveDossier.examination.type
    },
    ActiveDossierLastAction() {
      if (!this.HasActiveDossier) return ''
      return this.ActiveDossier.last_action.action
    },
    ActiveDossierLastActionDeadline() {
      if (!this.HasActiveDossier) return ''
      return this.ActiveDossier.last_action.deadline_formatted
    },
    HasFiles() {
      if (!this.see_individual) {
        return this.action.files.length > 0
      } else {
        return this.ActiveDossier.new_actions.files.length > 0
      }
    },
    HasActiveDossier() {
      return this.ActiveDossier !== undefined
    },
    ActiveDossier() {
      return this.selected_dossiers[this.active_dossier]
    },
    TotalDossiers() {
      return this.selected_dossiers.length
    },
    CurrentDossier() {
      return this.active_dossier + 1
    }
  },
  methods: {
    close() {
      if (!this.is_uploading) this.$emit('close')
    },
    mapDossiers() {
      this.selected_dossiers = this.selected_dossiers
                                   .map(dossier => {
                                     return {
                                       ...dossier,
                                       new_actions: {
                                         action_id: this.action.action_id,
                                         executed_at: this.action.execution_date,
                                         deadline: this.action.deadline,
                                         comment: this.action.comments,
                                         files: this.action.files
                                       }
                                     }
                                   })
    },
    showIndividualFiles() {
      this.mapDossiers()
      this.see_individual = true
    },
    processActionsAsBatch() {
      this.mapDossiers()
      this.processDossiers()
    },
    async processDossiers() {
      this.is_uploading = true

      const selected_dossiers = this.selected_dossiers
      const promiseArray = []

      await selected_dossiers
          .map(dossier => {
            return {
              dossier_id: dossier.id,
              action: new CreateAction({
                action_id: dossier.new_actions.action_id,
                executed_at: dossier.new_actions.executed_at,
                deadline: dossier.new_actions.deadline,
                comment: dossier.new_actions.comment
              }),
              files: dossier.new_actions.files
            }
          })
          .forEach(dossier => {
            if (dossier.action.isValid()) {
              const promise = new Promise(resolve => {
                APIService
                    .postLogbookItem(dossier.action.preparePost(), dossier.dossier_id)
                    .then((response) => {

                      const promiseArray = this.createPromiseArray(dossier.files, response.data.id)
                      Promise.all(promiseArray).then(() => {

                        if (this.upload_dossier !== this.TotalDossiers) {
                          this.upload_dossier++
                        }

                        resolve()
                      })

                    })
              })
              promiseArray.push(promise)
            }
          })

      Promise.all(promiseArray)
             .then(() => {
               this.$store.dispatch('DossierModule/fetchDossiers', false)
                   .then(() => {
                     //this.$store.dispatch('DossierModule/clearSelectedDossiers')
                     this.throwSuccessMessage('Alle acties zijn toegevoegd')
                     this.$emit('close')
                     this.is_uploading = false
                     this.active_dossier = 0;
                     this.upload_dossier = 1
                   })
             })

        this.$store.dispatch('DossierModule/clearSelectedDossiers')
    },
    previousDossier() {
      if (this.active_dossier !== 0 && !this.is_uploading) {
        this.active_dossier--
      }
    },
    nextDossier() {
      this.active_dossier++
    },
    addFiles(files) {
      if (this.see_individual) {
        this.ActiveDossier.new_actions.files.push(...files)
      } else {
        this.action.files.push(...files)
      }
    },
    removeFile(index) {
      if (this.see_individual) {
        this.ActiveDossier.new_actions.files.splice(index, 1)
      } else {
        this.action.files.splice(index, 1)
      }
    }
  },
  mounted() {
    this.selected_dossiers = this.$store.getters["DossierModule/getSelectedDossiers"].map(dossier => {
      return {
        ...dossier,
        new_actions: {
          action_id: '',
          executed_at: new Date(),
          deadline: null,
          comment: '',
          files: []
        }
      }
    })
  },
  created() {
    this.action.action_id = this.$store.state.DataModule.actions[0].id
  }
}
</script>

<style lang="scss" scoped>
.individual-actions-grid {
  grid-template-areas: '1 2' '3 3';
  grid-gap: 16px;

  @screen md {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
