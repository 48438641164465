<template>
  <div class="mb-20p">
    <vl-textarea label="Wens je opmerkingen toe te voegen?"
                 placeholder="Schrijf een opmerking…" v-model="Model"></vl-textarea>
  </div>
</template>

<script>
import VlTextarea from "@/components/Elements/Form/Elements/VlTextarea";

export default {
  name: "VlModalComment",
  components: {
    VlTextarea
  },
  computed: {
    Model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
