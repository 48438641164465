<template>
  <div><label :for="forId" class="text-blue-dark text-12p 2xl:text-14p select-none">{{ label }}</label></div>
</template>
<script>
export default {
  name: "VlLabel",
  props: {
    label: {
      type: String,
      required: true
    },
    forId: {
      type: [Number, String],
      required: false,
      default: ''
    },
  }
}
</script>

<style scoped>

</style>
