import {DateHelper} from "@/mixins/formatDate";

export default class LogEntry {

    id = null
    comment = ''
    deadline = ''
    deadline_formatted = ''
    executed_at = ''
    executed_at_formatted = ''
    executed_by = ''
    executed_location = ''
    name = ''

    constructor(data) {
        this.id = data.id
        this.comment = data.comment
        this.name = data.name

        this.setExecutedBy(data)
        this.setDeadline(data)
        this.setExecutedAt(data)

    }

    setExecutedBy(data) {
        if (typeof data.executed_by === "string") {
            const splittedString = data.executed_by.split('<>')

            this.executed_by = splittedString[0]
            if (splittedString.length > 1) {
                this.executed_location = splittedString[1]
            }
        }
    }

    setDeadline(data) {
        if (data.deadline) {
            this.deadline = DateHelper.formatUnixToDate(data.deadline)
            this.deadline_formatted = DateHelper.formatDateToString(this.deadline)
        }
    }

    setExecutedAt(data) {
        if (data.executed_at){
          this.executed_at = DateHelper.formatUnixToDate(data.executed_at)
            this.executed_at_formatted = DateHelper.formatDateToString(this.executed_at, 'DD/MM/YYYY HH:mm')
        }
    }
}
