

export const ToastMixin = {
    methods: {
    throwSuccessMessage(message) {
            this.$toastr.Add({
                name: "ToastSuccessMessage",
                title: "Gelukt", // Toast Title
                msg: message, // Toast Message
                clickClose: true, // Click Close Disable
                timeout: 3000, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
                position: "toast-bottom-right", // Toast Position.
                // type: "siccess", // Toast type,
                classNames: ['bg-green', 'rounded-4p'],
                style: {width: "300px"}
            });
        },
        throwErrorMessage(message) {
            this.$toastr.Add({
                name: "ToastErrorMessage",
                title: "Foutmelding", // Toast Title
                msg: message, // Toast Message
                clickClose: true, // Click Close Disable
                timeout: 3000, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
                position: "toast-bottom-right", // Toast Position.
                // type: "error", // Toast type,
                classNames: ['bg-red', 'rounded-4p'],
                style: {width: "300px"}
            });
        }
        ,throwWarningMessage(message) {
            this.$toastr.Add({
                name: "ToastWarningMessage",
                title: "Waarschuwing", // Toast Title
                msg: message, // Toast Message
                clickClose: true, // Click Close Disable
                timeout: 3000, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
                position: "toast-bottom-right", // Toast Position.
                // type: "warning", // Toast type,
                classNames: ['bg-orange', 'rounded-4p'],
                style: {width: "300px"}
            });
        }
        ,
        throwInformationMessage(message) {
            this.$toastr.Add({
                name: "ToastInformationMessage",
                title: "Informatie", // Toast Title
                msg: message, // Toast Message
                clickClose: true, // Click Close Disable
                timeout: 3000, // Remember defaultTimeout is 5 sec.(5000) in this case the toast won't close automatically
                position: "toast-bottom-right", // Toast Position.
                // type: "info", // Toast type,
                classNames: ['bg-blue-dark', 'rounded-4p'],
                style: {width: "300px"}
            });
        }

    }
}
