<template>
  <div
      class="grid grid-cols-min-1fr-min gap-16p border border-solid p-8p rounded-4p border-gray-border hover:bg-gray-light-30 cursor-pointer mb-8p"
      @click="deleteFile">
    <div class="self-center text-blue-dark">
      <font-awesome-icon icon="file"/>
    </div>
    <div class="overflow-hidden text-14p self-center">{{ FileName }}</div>
    <div class="self-center">
      <font-awesome-icon icon="trash-alt" class="text-14p text-blue-light" v-show="canDelete" @click="$emit('remove')"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "VlModalShowFile",
  props: {
    item: {
      required: true,

    },
    canDelete: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    FileName() {
      return this.item.name
    }
  },
  methods: {
    deleteFile() {
      if (this.canDelete) this.$emit('remove')
    }
  }
}
</script>

<style scoped>

</style>
