<template>
  <label :for="`${id_prefix}_checkbox_${id}`" class="grid cursor-pointer" :class="CheckboxGrid" @click="$emit('click')">
    <div class="place-self-center">
      <div class="w-14p h-14p relative bg-blue-light rounded-3p relative" v-show="isActive">
        <div class="absolute w-14p" style="top:50%; left:50%; transform: translate(-50%, -50%)">
          <span class="text-white inline-block text-10p pl-2p pb-5p">
            <font-awesome-icon icon="check"/>
          </span>
        </div>
      </div>
      <div class=" w-14p h-14p rounded-3p bg-white shadow-checkbox-inner border border-solid border-gray-cover"
           v-show="!isActive"></div>
    </div>
    <div v-if="label" class="self-center">
      <span class="text-14p whitespace-nowrap select-none">{{ label }}</span>
    </div>
    <input :id="`${id_prefix}_checkbox_${id}`" ref="checkbox" type="checkbox" :value="modelValue" v-model="Model"
           class="opacity-0 absolute">
  </label>
</template>

<script>

import {randomNumber} from "@/mixins/RandomNumber.mixin";

export default {
  name: "VlCheckbox",
  watch: {
    value: {
      handler: function () {
        this.onChange()
      }
    },
    Model: {
      handler: function () {
        this.onChange()
      }
    }
  },
  props: {
    id_prefix: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: null
    },
    modelValue: {
      type: [String, Boolean, Number, Object],
      required: false,
      default: null
    },
    value: {
      type: [Array, Boolean]
    }
  },
  data() {
    return {
      isActive: false,
      id: randomNumber(5000)
    }
  },
  computed: {
    Model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    CheckboxGrid() {
      if (this.label) {
        return 'grid-cols-min-min gap-2'
      }
      return ''
    }
  },
  methods: {
    onChange() {
      this.$nextTick(() =>{
        this.isActive = this.$refs.checkbox.checked
      })
    }
  },
  mounted() {
    this.onChange()
  }
}
</script>

<style scoped>

</style>
