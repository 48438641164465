export const ProcessFilesMixin = {
    methods: {
        processFile(content, filename, extension = 'pdf') {
            const decodedContent = this.to_array_buffer(this.decode_base_64(content))
            let blob = new Blob([decodedContent]);

            let link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = filename ?? `download.${extension}`;
            link.target = "_blank";
            link.id = 'file_download'
            document.getElementById('app').append(link)
            const appendedLink = document.getElementById('file_download')
            appendedLink.click();
            appendedLink.remove()
        },
        decode_base_64(content) {
            return atob(content)
        },
        to_array_buffer(content){
            var binaryLen = content.length;
            var bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
                bytes[i] = content.charCodeAt(i);
            }
            return bytes;
        }
    }
}
