import * as XLSX from 'xlsx'

export const generateOvamExcel = (dossiers) => {
  const allDossiersArray = []

  const headers = [
    'Dossiernummer',
    'Type',
    'Resultaat',
    'Keuring',
    'Hersteltermijn',
    'Nieuwe keuring',
    'Adres',
    'Laatste actie',
    'Datum actie',
    'Deadline',
    // 'Herstelmaatregelen',
    // 'Aandachtspunten'
  ]
  dossiers.forEach((dossier) => {

    const singleDossierArray = [
      dossier.examination.dossier_number ?? '/',
      dossier.examination.type ?? '/',
      dossier.examination.result ?? '/',
      dossier.examination.date_formatted ?? '/',
      dossier.examination.term_formatted ?? '/',
      dossier.examination.review ? 'Ja' : 'Nee',
      `${dossier.address.street_name} ${dossier.address.street_number}${dossier.address.street_number_addition}, ${dossier.address.city_postcode} ${dossier.address.city_name}`,
      dossier.last_action.action ?? '/',
      dossier.last_action.executed_at_formatted ?? '/',
      dossier.last_action.deadline_formatted ?? '/',
    ]
    allDossiersArray.push(singleDossierArray)
  })
  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.json_to_sheet([headers, ...allDossiersArray], {
    header: [],
    skipHeader: true,
  })
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Dossiers')
  const now = new Date().toLocaleDateString()
  XLSX.writeFile(workbook, `dossiers_${now}.xlsx`)
}