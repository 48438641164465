<template>
  <span @click="$emit('click')" :class="Class" class="text-center">
    {{ value }}
  </span>
</template>

<script>
export default {
  name: "VlPaginationItem",
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    active: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    Class() {
      const classes = ['text-14p', '2xl:text-16p', 'text-blue-dark', 'cursor-pointer', 'inline-block', 'mr-8p']
      if (this.active) {
        classes.push('font-600', 'underline')
      }
      return classes.join(' ')
    }
  }
}
</script>

<style scoped>

</style>
