<template>
  <div>
    <vl-mobile-filter-template label="Laatst toegepaste actie" :open="open" :toggleable="toggleable" @clear-filter="$store.dispatch('DossierModule/FilterModule/clearLastActionFilter')">
      <vl-checkbox v-model="Model" id_prefix="last_action" :model-value="action" :label="action.name" :key="action.name" v-for="action in $store.getters['DataModule/getActions']"/>
    </vl-mobile-filter-template>
  </div>
</template>

<script>
import VlMobileFilterTemplate from "@/components/Elements/Filters/VlFilterTemplate";
import VlCheckbox from "@/components/Elements/Form/Elements/VlCheckbox";

export default {
  name: "VlMobileLastActionFilter",
  components: {
    VlMobileFilterTemplate,
    VlCheckbox
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: true
    },
    toggleable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed:{
    Model: {
      get() {
        return this.$store.state.DossierModule.FilterModule.filters.last_action
      },
      set(value) {
        this.$store.dispatch('DossierModule/FilterModule/updateFilter',
            {
              filter: 'last_action',
              data: value
            })
      }
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>
