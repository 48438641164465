import Address from "@/services/models/Address";
import Contact from "@/services/models/Contact";
import LogEntry from "@/services/models/LogEntry";

export default class DossierDetail {
    id = null
    address = new Address()
    log = {
        actions: []
    }
    measures = []
    attention = []
    attachments = []
    internal_documents = []

    contact = {
        customer: {},
        owner: {}
    }
    examination = {}
    new_examinations = []
    old_examinations = []
    last_action = {}

    archived = false

    constructor(data) {
        this.id = data.id
        this.setAddress(data)
        this.setContacts(data)
        this.setLog(data)
        this.setMeasures(data)
        this.setAttention(data)
        this.setAttachments(data)
        this.setInternalDocuments(data)
        this.setExamination(data)
        this.setNewExaminations(data)
        this.setOldExaminations(data)


        if (data.archived) this.archived = data.archived
    }

    setAddress(data) {
        this.address = new Address()

        if (data.address) {
            this.address.setAddress(data.address)
        }
    }

    setContacts(data) {
        this.contact.customer = new Contact()
        this.contact.owner = new Contact()

        if (data.contact.customer) this.contact.customer.setContact(data.contact.customer)
        if (data.contact.owner) this.contact.owner.setContact(data.contact.owner)
    }

    setLog(data) {
        if (data.log.actions) {
            this.log.actions = data.log.actions.map(entry => new LogEntry(entry))
        }
    }

    setMeasures(data) {
        if (data.measures) this.measures = data.measures
    }

    setAttention(data) {
        if (data.attention) this.attention = data.attention
    }

    setAttachments(data) {
        if (data.attachments) this.attachments = data.attachments
    }

    setInternalDocuments(data) {
        if (data.internal_documents) this.internal_documents = data.internal_documents
    }

    setExamination(data) {
        if (data.examination) this.examination = data.examination
    }

    setNewExaminations(data) {
        if (data.new_examinations) this.new_examinations = data.new_examinations
    }

    setOldExaminations(data) {
        if (data.old_examinations) this.old_examinations = data.old_examinations
    }


}
