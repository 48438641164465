<template>
  <div>
    <vl-label :for-id="id" :label="label"/>
    <label :for="id" class="relative inline-block w-full flex border border-solid border-gray-border rounded-4p">
      <select :id="id" v-model="Model"
              class="w-full  p-6p pl-16p bg-gray-border-30 appearance-none overflow-hidden">
        <option value="" disabled selected v-if="placeholder">{{ placeholder }}</option>
        <option :key="`${id}_${value.id}`" :value="value.id" v-for="value in values" class="text-14p">{{ value.label }}
        </option>
      </select>

      <div class="bg-gray-border-30 absolute right-0 w-16p pointer-events-none">
        <span class="absolute top-6p right-8p text-blue-dark pointer-events-none"><font-awesome-icon icon="chevron-down" class="text-12p"/></span>
      </div>

    </label>
  </div>
</template>

<script>
import VlLabel from "@/components/Elements/Form/Elements/VlLabel";
import {randomNumber} from "@/mixins/RandomNumber.mixin";

export default {
  name: "VlSelect",
  components: {
    VlLabel
  },
  props: {
    value: {
      required: true,
    },
    placeholder: {
      value: String,
      required: false,
      default: null
    },
    values: {
      type: Array,
      required: true,
      default: () => []
    },
    label: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      id: randomNumber()
    }
  },
  computed: {
    Model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
