<template>
    <button class="rounded-4p py-0.5 px-1.5 transition duration-150 ease-in" @click="$emit('click')" :class="classes">
      <span v-if="iconLeft" class="inline-block mr-8p"><font-awesome-icon :icon="iconLeft" class="text-white text-14p" /></span>
      <span class="text-white" v-if="label">{{ label }}</span>
      <span v-if="iconRight" class="inline-block ml-8p"><font-awesome-icon :icon="iconRight" class="text-white text-14p" /></span>
    </button>
</template>

<script>
export default {
  name: "VlButton",
  props: {
    label: {
      type: String,
      required: false,
      default: null
    },
    iconLeft: {
      type: String,
      required: false,
      default: null
    },
    iconRight: {
      type: String,
      required: false,
      default: null
    },
    classes: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
