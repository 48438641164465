<template>
  <div class="text-14p text-blue-light text-center">{{ message }}</div>
</template>

<script>
export default {
  name: "VlNotAvailableNotification",
  props: {
    message: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
