<template>
  <div class="bg-white h-screen fixed top-0 z-20 p-30p grid grid-rows-mobile-filter-menu w-800p max-w-800p">
    <vl-filter-element-header @click="$store.dispatch('toggleFilterMenu')"/>
    <div class="grid gap-20p overflow-y-auto" style="grid-template-columns: 1fr 1px 1fr">
      <div class="space-y-30p">
        <type-of-examination-filter :toggleable="false" :open="true"/>
        <result-of-examination-filter :toggleable="false" :open="true"/>
        <examination-date-filter :toggleable="false" :open="true"/>
        <no-examination-filter :toggleable="false" :open="true"/>
        <show-new-examination-filter :toggleable="false" :open="true"/>
        <measures-filter :toggleable="false" :open="true" />
      </div>
      <div class="border-r border-gray-border border-solid"/>
      <div class="space-y-30p">
        <last-action-filter :toggleable="false" :open="true"/>
        <deadline-filter :toggleable="false" :open="true"/>
        <city-filter :toggleable="false" :open="true"></city-filter>
        <address-filter :toggleable="false" :open="true"/>
      </div>
    </div>
    <div class="flex justify-end border-t border-gray-border border-solid mt-30p pt-30p">
      <div class="grid grid-cols-min-min mr-16p cursor-pointer">
        <span class="text-blue-dark inline-block mr-8p self-center"> <font-awesome-icon icon="times"/></span>
        <span class="text-blue-dark text-12p 2xl:text-14p whitespace-nowrap self-center"
              @click="$store.dispatch('DossierModule/FilterModule/clearAllFilters')">Wis alle filters</span>
      </div>


      <vl-button :label="`Toon ${$store.getters['DossierModule/getTotalFilteredDossiers']} resultaten`"
                 @click="$store.dispatch('toggleFilterMenu')" classes="px-64p py-8p text-14p bg-blue-dark hover:bg-blue-dark-hover"/>
    </div>
  </div>
</template>

<script>
import VlFilterElementHeader from "@/components/Elements/Filters/VlFilterElementHeader";
import VlButton from "@/components/Elements/VlButton";
import * as Filters from '@/components/Elements/Filters/Filters'

export default {
  name: "VlIndexFilters",
  components: {
    VlFilterElementHeader,
    VlButton,
    'type-of-examination-filter': Filters.VlTypeOfExaminationFilter,
    'result-of-examination-filter': Filters.VlResultOfExaminationFilter,
    'last-action-filter': Filters.VlLastActionFilter,
    'examination-date-filter': Filters.VlExaminationDateFilter,
    'deadline-filter': Filters.VlDeadlineFilter,
    'address-filter': Filters.VlAddressFilter,
    'show-new-examination-filter': Filters.VlNewExaminationFilter,
    'measures-filter': Filters.VlMeasuresFilter,
    'no-examination-filter': Filters.VlNoExaminationFilter,
    'city-filter' : Filters.VlCityFilter
  }
}
</script>

<style scoped>

</style>
