import {DateHelper} from "@/mixins/formatDate";

export class LastAction {
    action = ''
    executed_at = null
    executed_at_formatted = null
    deadline = null
    deadline_formatted = null
    comment = ''

    /**
     *
     * @param {{ action: String, executed_at: Date , deadline: Date, comment: String}} data
     */
    constructor(data) {

        this.action = data.action
        this.executed_at = data.executed_at
        this.deadline = data.deadline
        this.comment = data.comment

        this.processDates()
    }

    processDates() {
        if (this.deadline) this.deadline_formatted = DateHelper.formatDateToString(this.deadline)
        if (this.executed_at) this.executed_at_formatted = DateHelper.formatDateToString(this.executed_at)
    }
}
