<template>
  <div class="modal-participant border-b border-solid border-gray-border-30 pb-10p mb-10p pl-20p">
    <div class="flex justify-between">
      <div>
        <div class="mr-16p text-12p">{{ User }}</div>
        <div class="text-12p text-gray-light">{{ CreatedAt }}</div>
      </div>
      <div class="text-12p text-gray-light cursor-pointer" @click="$emit('remove')">
        Reactie verwijderen
      </div>
    </div>
    <div class="text-14p lg:text-16p">
      {{ Comment }}
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "VlModalParticipant",
  props: {
    participant: {
      type: Object,
      required: true
    }
  },
  computed: {
    Comment() {
      return this.participant.comment
    },
    User() {
      if (typeof this.participant.executed_by !== 'string') return '-'
      const index = this.participant.executed_by.indexOf('<')
      return this.participant.executed_by.slice(0, index)
    },
    CreatedAt() {
      return dayjs.unix(this.participant.created_at).format('DD/MM/YYYY HH:mm')
    }
  }
}
</script>

<style>
</style>
