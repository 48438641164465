import * as APIService from '@/services/ApiService'

export const UploadFilesMixin = {
    methods: {
        getFilesFromList(fileList) {
            return Array.from(Array(fileList.length).keys())
                .map(index => {
                    return fileList[index]
                })
        },
        createPromiseArray(files, id) {
            const promiseArray = []
            if (files.length === 0) return promiseArray

            files.forEach(item => {
                promiseArray.push(new Promise(resolve => {
                    const formData = new FormData()
                    formData.append('file', item)

                    APIService
                        .storeFile(formData, id)
                        .then(() => resolve())
                }))
            })

            return promiseArray
        }
    }
}
