import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index'
import store from '@/store/index'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: Index,
        beforeEnter: (to, from, next) => {

            store.dispatch('DossierModule/fetchDossiers',true)
                .then(() => {
                    next()
                })
        }
    },
    {
        path: '/details/:id',
        name: 'details',
        component: () => import( '../views/Details.vue'),
        beforeEnter: (to, from, next) => {
            next()
        }
    },
    {
        path: '*',
        component: Index
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
