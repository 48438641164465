<template>
  <div :class="{'flex justify-between' : flex}" class="self-center cursor-pointer" @click="$emit('click')">
    <div class="xl:hidden"><span class="text-12p text-blue-light select-none">{{ label }}</span></div>
    <div class="xl:flex" :class="{'text-right lg:text-left' : !flex}">
      <slot></slot>
      <p class="" :class="ContentClass" v-html="content"></p>
    </div>
  </div>

</template>

<script>
export default {
  name: "VlIndexDossierCardElement",
  props: {
    label: {
      type: String,
      required: true
    },
    content: {
      type: [String, Number],
    },
    flex: {
      type: Boolean,
      required: true
    },
    contentClass: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ContentClass() {
      const DEFAULT_CLASSES = 'text-14p select-none break-all text-right lg:text-left '


      return DEFAULT_CLASSES.concat(this.contentClass)
    }
  }
}
</script>

