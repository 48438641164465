<template>
  <div class="mb-20p">
    <vl-label label="Wens je bijlagen toe te voegen?"/>

    <div class="grid grid-cols-1fr-min gap-8p ">
      <div v-show="HasFiles" class="max-h-500p overflow-y-auto overflow-x-hidden">
        <vl-modal-show-file
            :item="file"
            :key="`file_component_${index}`"
            v-for="(file,index) in value"
            @remove="removeFile(index)"
        />
      </div>
      <div>
        <vl-add-files-button @change="addFiles"/>
      </div>
    </div>
  </div>
</template>

<script>
import VlLabel from '@/components/Elements/Form/Elements/VlLabel'
import VlAddFilesButton from '@/components/Elements/Form/Elements/VlAddFilesButton'
import VlModalShowFile from '@/components/Elements/Modal/Elements/VlModalShowFile'

export default {
  name: "VlModalAddFiles",
  components: {
    VlLabel,
    VlAddFilesButton,
    VlModalShowFile
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {

    HasFiles() {
      return this.value.length !== 0;
    }
  },
  methods: {
    addFiles(files) {
      this.$emit('input', [...this.value, ...files])
    },
    removeFile(index) {
      let files = [...this.value]
      files.splice(index, 1)
      this.$emit('input', files)
    }
  }
}
</script>

<style scoped>

</style>
