<template>
<div>
  <vl-mobile-filter-template label="Resultaat keuring" :open="open" :toggleable="toggleable" @clear-filter="$store.dispatch('DossierModule/FilterModule/clearResultFilter')">
   <div class="grid grid-cols-4">
     <vl-checkbox v-model="Model" :key="`result_${item}`" :model-value="item" :label="item" v-for="item in $store.getters['DataModule/getResults']"/>
   </div>

  </vl-mobile-filter-template>
</div>
</template>

<script>
import VlMobileFilterTemplate from "@/components/Elements/Filters/VlFilterTemplate";
import VlCheckbox from "@/components/Elements/Form/Elements/VlCheckbox";
export default {
  name: "VlResultOfExaminationFilter",
  components:{
    VlMobileFilterTemplate,
    VlCheckbox
  },
  props:{
    open: {
      type: Boolean,
      required: false,
      default: true
    },
    toggleable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    Model: {
      get() {
        return this.$store.state.DossierModule.FilterModule.filters.result
      },
      set(value) {
        this.$store.dispatch('DossierModule/FilterModule/updateFilter',
            {
              filter: 'result',
              data: value
            })
      }
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>
