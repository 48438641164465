<template>
  <div class="cursor-pointer">
    <label
        class="inline-block relative w-36p h-36p bg-blue-light rounded-4p hover:bg-blue-light-hover transition duration-150 ease-in"
        :for="id">

      <span
          class="inline-block absolute text-14p top-50 text-white left-50 transform translate-x-center translate-y-center">
        <font-awesome-icon icon="plus"></font-awesome-icon>
      </span>
      <input :id="id" type="file" multiple class="opacity-0 absolute" @change="addFiles($event)">
    </label>
  </div>
</template>

<script>
import {randomNumber} from "@/mixins/RandomNumber.mixin";

export default {
  name: "VlAddFilesButton",
  data() {
    return {
      id: randomNumber()
    }
  },
  methods: {
    addFiles(event) {
      const fileList = event.target.files;

      if (fileList.length === 0) return

      const files = Array.from(Array(fileList.length).keys())
          .map(index => {
            return fileList[index]
          })

      this.$emit('change', files)
      event.target.value = ''
    }
  }
}
</script>

<style scoped>

</style>
