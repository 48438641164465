<template>
  <div
      class="grid grid-cols-3min gap-8p border border-gray-border border-solid rounded-4p px-8p py-4p mb-4p cursor-pointer hover:bg-gray-light-30"
      @click="downloadFile">
    <div class="w-12p mr-8p place-self-center">


      <svg viewBox="0 0 109 134" version="1.1" xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve"
           xmlns:serif="http://www.serif.com/"
           style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <path
            d="M83.333,33.333c-4.625,0 -8.333,-3.75 -8.333,-8.333l0,-16.667l25,25l-16.667,0Zm16.667,83.334c0,4.583 -3.75,8.333 -8.333,8.333l-75,-0c-4.625,-0 -8.334,-3.75 -8.334,-8.333l0,-100c0,-4.625 3.709,-8.334 8.334,-8.334l49.875,0c-0.084,9.959 0.083,16.667 0.083,16.667c0,9.167 7.458,16.667 16.667,16.667l16.666,-0l0,75l0.042,-0Zm-25,-116.709l0,0.125c-0.542,0 -2.792,-0.208 -8.333,-0.125l-50,0c-9.209,0 -16.667,7.459 -16.667,16.667l0,100c0,9.167 7.458,16.667 16.667,16.667l75,-0c9.166,-0 16.666,-7.5 16.666,-16.667l0,-83.333l-33.333,-33.334Z"
            style="fill:#194670;stroke:#194670;stroke-width:4.17px;"/>
      </svg>

    </div>
    <div class="text-14p place-self-center whitespace-nowrap">{{ attachment.document }}</div>
    <div class="place-self-center">
      <div class="w-14p h-14p text-blue-dark " v-if="!is_downloading">
        <img :src="require('@/assets/images/download.svg')" alt="">
      </div>
      <div v-if="is_downloading">
        <font-awesome-icon icon="circle-notch" class="fa-spin text-blue-dark text-14p"/>
      </div>
    </div>
  </div>
</template>

<script>
import * as APIService from '@/services/ApiService'
import {ToastMixin} from "@/mixins/ToastMixin";
import {ProcessFilesMixin} from "@/mixins/ProcessFiles.mixin";

export default {
  name: "VlModalActionAttachment",
  props: {
    attachment: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      is_downloading: false
    }
  },
  mixins: [ToastMixin, ProcessFilesMixin],
  methods: {
    downloadFile() {
      this.is_downloading = true
      APIService
          .downloadFile(this.attachment.url)
          .then(response => {
            this.processFile(response.data.content, response.data.filename, response.data.extension)
          })
          .catch(() => {
            this.throwErrorMessage('Er is iets misgelopen bij het ophalen van dit bestand. Gelieve contact op te nemen indien dit blijft aanhouden.')
          }).finally(() => {
        this.is_downloading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
