<template>
  <div class="grid grid-cols-3 bg-gray-light-20 mb-12p rounded-4p"
       v-show="$store.getters['DossierModule/getSelectedDossiers'].length > 0">
    <div class="pl-2 py-8p">
      <span class="text-14p select-none">{{
          $store.getters["DossierModule/getSelectedDossiers"].length
        }} dossier(s) geselecteerd</span>
    </div>
    <div class="py-8p grid grid-cols-min-min gap-8p place-content-center place-items-center">
      <span class="text-14p text-blue-dark whitespace-nowrap select-none">Kies een actie:</span>
      <vl-index-select-action v-model="selected_action"/>
    </div>
    <div class="grid grid-cols-min-min gap-8p place-content-end">
            <span class="text-14p font-600 cursor-pointer text-blue-light py-8p whitespace-nowrap place-self-center select-none"
                  @click="$store.dispatch('DossierModule/clearSelectedDossiers')">Selectie wissen</span>

      <vl-button label="Uitvoeren" classes="h-full bg-blue-dark hover:bg-blue-dark-hover px-35p select-none" @click="launchAction"/>
    </div>
  </div>
</template>

<script>
import VlButton from "@/components/Elements/VlButton";
import VlIndexSelectAction from "@/components/Index/VlIndexSelectAction";
import {ToastMixin} from "@/mixins/ToastMixin";

export default {
  name: "VlIndexSelectedDossiers",
  components: {
    VlButton,
    VlIndexSelectAction
  },
  mixins: [ToastMixin],
  data() {
    return {
      selected_action: ''
    }
  },
  methods: {
    launchAction() {
      if (this.selected_action.length === 0) {
        this.throwInformationMessage('Gelieve een actie te kiezen')
        return
      }

      if (this.selected_action?.id === 'ACTIE_TOEVOEGEN') {
        this.$emit('show-action-modal')
      }

      if (this.selected_action?.id === 'GENERATE_TEMPLATE') {
        this.$emit('show-templates-modal')
      }
    }
  }
}
</script>

<style scoped>

</style>
