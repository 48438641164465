<template>
<span class="inline-block bg-blue-light px-12p pb-2p rounded-full mx-4p my-4p select-none transition duration-200 ease-in hover:bg-blue-light-hover cursor-pointer" @click="clearFilter">
  <span class="text-white text-12p font-600 select-none">{{ filter.label }}</span>  <span class="text-12p text-white inline-block ml-8p"><font-awesome-icon
    icon="times"/></span>
</span>
</template>

<script>
export default {
  name: "VlShowFilterItem",
  props: {
    filter: {
      type: Object
    }
  },
  methods: {
    clearFilter() {
      this.$store.dispatch('DossierModule/FilterModule/clearFilter', {
        filter: this.filter.filter,
        item: this.filter.item
      })
    }
  }
}
</script>

<style scoped>

</style>
