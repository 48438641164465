import * as APIService from '@/services/ApiService'
import Dossier from "@/services/models/Dossier";
import {FilterModule} from "@/store/_dossiers_filters.module";
import {PaginationModule} from "@/store/_dossiers_pagination.module";
import {OrderByModule} from "@/store/_dossiers_order_by.module";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween'
import * as _ from 'lodash'

dayjs.extend(isBetween)

export const DossierModule = {
    namespaced: true,
    state: {
        show_current_dossiers: true,
        dossiers: [],
        selected_dossiers: []
    },
    getters: {
        getDossiers: state => state.dossiers,
        getDossierById: state => id => state.dossiers.find(dossier => dossier.id === id),
        getDossierStreets: state => state.dossiers.map(dossier => dossier.address.street_name),
        getDossierCities: state => {
            const citynames = state.dossiers.map(dossier =>dossier.address.city_name)
            const postcodes = state.dossiers .map(dossier => dossier.address.city_postcode)
            return _.uniq([...citynames,...postcodes])

        },
        getFilteredDossiers: (state, getters) => {
            return _
                .chain(state.dossiers)
                /** Zoekbox filters */
                .filter(dossier => {
                    if (state.FilterModule.filters.search.length === 0) return dossier


                    if (dossier.examination) {
                        if (dossier.examination.dossier_number) {
                            if (dossier.examination.dossier_number.toLowerCase().includes(getters["FilterModule/getSearchFilter"].toLowerCase())) return dossier
                        }
                    }

                    if (dossier.address) {
                        if (dossier.address.city_name) {
                            if (dossier.address.city_name.toLowerCase().includes(getters["FilterModule/getSearchFilter"])) return dossier
                        }
                        if (dossier.address.city_postcode) {
                            if (dossier.address.city_postcode.includes(getters["FilterModule/getSearchFilter"])) return dossier
                        }

                        if (dossier.address.street_name) {
                            if (dossier.address.street_name.toLowerCase().includes(getters["FilterModule/getSearchFilter"])) return dossier
                        }
                    }

                })
                /** Filteren op type dossier */
                .filter(dossier => {
                    if (state.FilterModule.filters.type.length === 0) return dossier
                    if (state.FilterModule.filters.type.some(type => type.code === dossier.examination.type)) return dossier
                })
                /** Filteren op het resultaat */
                .filter(dossier => {
                    if (state.FilterModule.filters.result.length === 0) return dossier
                    if (state.FilterModule.filters.result.includes(dossier.examination.result)) return dossier
                })
                /** Filteren op de laatste actie */
                .filter(dossier => {
                    if (state.FilterModule.filters.last_action.length === 0) return dossier
                    if (state.FilterModule.filters.last_action.some(action => action.name === dossier.last_action.action)) return dossier
                })
                /** Filteren op de datum van het onderzoek */
                .filter(dossier => {
                    if (!state.FilterModule.filters.examination_date.from || !state.FilterModule.filters.examination_date.to) return dossier
                    if (dayjs.unix(dossier.examination.date).isBetween(dayjs(state.FilterModule.filters.examination_date.from), dayjs(state.FilterModule.filters.examination_date.to), null, '[]')) return dossier
                })
                /** Filteren op de deadline */
                .filter(dossier => {
                    if (!state.FilterModule.filters.deadline.from || !state.FilterModule.filters.deadline.to) return dossier
                    if (!dossier.last_action.deadline) return
                    if (dayjs(dossier.last_action.deadline).isBetween(dayjs(state.FilterModule.filters.deadline.from), dayjs(state.FilterModule.filters.deadline.to), null, '[]')) return dossier
                })
                /** Filteren of de deadline al voorbij is */
                .filter(dossier => {
                    if (!state.FilterModule.filters.deadline_passed) return dossier
                    if (!dossier.last_action.deadline) return
                    if (dayjs(dossier.last_action.deadline).isBefore(dayjs())) return dossier
                })
                /** Filter op stadsnaam*/
                .filter(dossier => {
                    if (state.FilterModule.filters.city.length === 0) return dossier
                    if (state.FilterModule.filters.city.includes(dossier.address.city_name)) return dossier
                    if (state.FilterModule.filters.city.includes(dossier.address.city_postcode)) return dossier
                })
                /** Filter op de straatnaam */
                .filter(dossier => {
                    if (state.FilterModule.filters.street.length === 0) return dossier
                    if (state.FilterModule.filters.street.includes(dossier.address.street_name)) return dossier
                })
                /** Filter op dossiers met nieuwe keuringen*/
                .filter(dossier => {
                    if (!state.FilterModule.filters.only_with_new_examination) return dossier
                    if (dossier.examination.review) return dossier
                })
                /** Filter op dossiers zonder nieuwe keuringen */
                .filter(dossier => {
                    if (!state.FilterModule.filters.only_without_new_examination) return dossier
                    if (!dossier.examination.review) return dossier
                })
                /** Filter op dossier die geen keuring hebben*/
                .filter(dossier => {
                    if (!state.FilterModule.filters.no_examination) return dossier
                    if (dossier.examination.date) return dossier
                })
                /** Filter op dossiers die een bouwvergunning hebben */
                .filter(dossier => {
                    if (!state.FilterModule.filters.from_building_permit) return dossier
                    if (dossier.building_permit) return dossier
                })
                /** Filter op dossiers die afgesloten gaan worden */
                .filter(dossier => {
                    if (!state.FilterModule.filters.from_disconnection_project_building) return dossier
                    if (dossier.disconnection_project_building) return dossier
                })
                /** Filter op hoofdstuk */
                .filter(dossier => {
                    if (!state.FilterModule.filters.measure_chapter) return dossier
                    if (dossier.measure_chapters.includes(state.FilterModule.filters.measure_chapter)) return dossier
                })
                /** Filter op maatregeling */
                .filter(dossier => {
                    if (!state.FilterModule.filters.measure) return dossier
                    if (dossier.measures.includes(state.FilterModule.filters.measure)) return dossier
                })
                /** Filter op aanmaakdatum van het dossier */
                .filter(dossier => {
                    if (!state.FilterModule.filters.dossier_created_at.from || !state.FilterModule.filters.dossier_created_at.to) return dossier
                    if (!dossier.created_at) return dossier
                    if (dayjs(dossier.created_at).isBetween(dayjs(state.FilterModule.filters.dossier_created_at.from), dayjs(state.FilterModule.filters.dossier_created_at.to), null, '[]')) return dossier
                })
                .uniqBy('id')
                .value()
        },
        getOrderedDossiers: (state, getters) => _.chain(getters.getFilteredDossiers).orderBy(getters["OrderByModule/getSort"], getters["OrderByModule/getOrders"]).value(),
        getPaginatedDossiers: (state, getters) => getters.getOrderedDossiers.slice(getters["PaginationModule/getSliceStart"], getters["PaginationModule/getSliceEnd"]),
        getTotalFilteredDossiers: (state, getters) => getters.getFilteredDossiers.length,
        getSelectedDossiers: state => state.selected_dossiers,
        isSelected: state => id => state.selected_dossiers.filter(dossier => dossier.id === id).length === 1,
        areAllDossiersSelected: (state, getters) => {
            return getters.getSelectedDossiers.filter(selected_dossier => {
                return getters.getPaginatedDossiers.some(dossier => dossier.id === selected_dossier.id)
            }).length === getters.getPaginatedDossiers.length
        }
    },
    mutations: {
        SET_DOSSIERS: (state, payload) => state.dossiers = payload,
        CLEAR_SELECTED_DOSSIERS: (state) => state.selected_dossiers = [],
        MODEL_SELECTED_DOSSIER: (state, payload) => state.selected_dossiers = payload,
        ADD_TO_SELECTED_DOSSIERS: (state, payload) => state.selected_dossiers.push(...payload),
        REMOVE_FROM_SELECTED_DOSSIERS: (state, index) => state.selected_dossiers.splice(index, 1),
        UPDATE_LAST_ACTION: (state, payload) => state.dossiers.splice(payload.index, 1, payload.dossier),
        SET_SHOW_CURRENT_DOSSIERS: (state, payload) => state.show_current_dossiers = payload
    },
    actions: {
        fetchDossiers: async ({state, dispatch, commit}, display_loading_screen = true) => {
            let call;

            if (display_loading_screen) {
                dispatch('setLoaded', false, {root: true})
            }

            if (state.show_current_dossiers) {
                call = APIService.getDossiers()
            }

            if (!state.show_current_dossiers) {
                call = APIService.getArchivedDossiers()
            }

            await call.then(response => {
                const mappedDossiers = response.data.map(dossier => new Dossier(dossier))
                commit('SET_DOSSIERS', mappedDossiers)
                if (display_loading_screen) {
                    dispatch('setLoaded', true, {root: true})
                }
            })
        },
        modelSelectedDossiers: ({commit}, payload) => commit('MODEL_SELECTED_DOSSIER', payload),
        updateSelectedDossiers: ({state, dispatch}, payload) => {
            const hasDossier = state.selected_dossiers.filter(e => e.id === payload.id).length === 1
            let data;
            if (hasDossier) {
                data = state.selected_dossiers.filter(e => e.id !== payload.id)
            } else {
                data = [...state.selected_dossiers, payload]
            }

            dispatch('modelSelectedDossiers', data)
        },
        clearSelectedDossiers: ({commit}) => commit('CLEAR_SELECTED_DOSSIERS'),
        selectAllDossiers: ({getters, dispatch, commit}) => {

            if (getters.areAllDossiersSelected) {
                const data = getters.getSelectedDossiers.filter(selected_dossier => {
                    return !getters.getFilteredDossiers.some(dossier => dossier.id === selected_dossier.id)
                })
                dispatch('modelSelectedDossiers', data)
            } else {
                const data = getters.getFilteredDossiers.filter(dossier => {
                    return !getters.getSelectedDossiers.some(selected_dossier => dossier.id === selected_dossier.id)
                })
                commit('ADD_TO_SELECTED_DOSSIERS', data)
            }
        },
        updateLastAction: ({state, commit}, payload) => {
            const index = state.dossiers.findIndex(dossier => dossier.id === payload.id)

            if (index !== -1) {
                const dossier = {...state.dossiers[index], last_action: {...payload.last_action}}

                commit('UPDATE_LAST_ACTION', {
                    index: index,
                    dossier: dossier
                })
            }
        },
        setShowCurrentDossiers: ({commit, dispatch}, payload) => {
            commit('SET_SHOW_CURRENT_DOSSIERS', payload)
            dispatch('fetchDossiers', true)
        }
    },
    modules: {
        FilterModule,
        PaginationModule,
        OrderByModule
    }
}
