<template>
  <div>
    <vl-label :for-id="id" :label="label" v-if="label"/>
    <div>
      <textarea :id="id" :placeholder="placeholder" v-model="Model"
                class="border border-solid rounded-4p p-6p w-full border-gray-border bg-gray-border-30"></textarea>
    </div>
  </div>
</template>

<script>
import VlLabel from "@/components/Elements/Form/Elements/VlLabel";
import * as Mixins from '@/mixins/mixins'
import {randomNumber} from "@/mixins/RandomNumber.mixin";

export default {
  name: "VlTextarea",
  components: {
    VlLabel
  },
  mixins: [
    Mixins.RandomNumber
  ],
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      id: randomNumber()
    }
  },
  computed: {
    Model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss">
textarea{
@apply focus:outline-none;
}

textarea::placeholder {
  @apply text-14p;
  @apply text-gray-border;
}
</style>
