<template>
  <div>
  <vl-mobile-filter-template label="Stad/Gemeente" :open="open" :toggleable="toggleable" @clear-filter="$store.dispatch('DossierModule/FilterModule/clearCitynameFilter')">
    <div>
      <vl-textfield placeholder="Filter op een stad, gemeente of postcode" v-model="search"/>
    </div>
    <div class="relative z-10">
      <div
          class="absolute shadow-card bg-white border border-solid border-gray-border w-full max-h-40 overflow-auto z-20"
          v-show="Display">
        <div :key="`filtered_cities_${name}_${index}`"
             class="filtered-item"
             v-for="(name,index) in Filterednames" @click.stop="addToFilter(name)">
          {{ name }}
        </div>

        <div v-if="Filterednames.length === 0" class="text-blue-light text-14p p-8p">
          Deze stad is niet gevonden
        </div>
      </div>

      <div class="relative z-10 max-w-full mt-16p">
        <div :key="filter.item" v-for="filter in $store.getters['DossierModule/FilterModule/selectedCityFilter']">
          <vl-show-filter-item :filter="filter"/>
        </div>
      </div>
    </div>
  </vl-mobile-filter-template>
  </div>
</template>

<script>
import VlMobileFilterTemplate from "@/components/Elements/Filters/VlFilterTemplate";
import VlTextfield from "@/components/Elements/Form/Elements/VlTextfield";
import VlShowFilterItem from "@/components/Elements/Filters/VlShowFilterItem";
import * as _ from 'lodash'
export default {
  name: "VlCityFilter",
  components: {
    VlMobileFilterTemplate,
    VlTextfield,
    VlShowFilterItem
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: true
    },
    toggleable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      search: ''
    }
  },
  computed: {
    Cities() {
      return this.$store.getters["DossierModule/getDossierCities"]
    },
    Display() {
      return this.Filterednames.length < 50 && this.search.length !== 0
    },
    Filterednames() {
      return _
          .chain(this.Cities)
          .filter(name => name.toLowerCase().includes(this.search.toLowerCase()))
          .uniq()
          .value();
    }
  },
  methods: {
    addToFilter(name) {
      this.$store.dispatch('DossierModule/FilterModule/updateCityFilter', name)
      this.search = ''
    }
  }
}
</script>

<style scoped>
.filtered-item{
  @apply text-blue-light;
  @apply transition;
  @apply duration-200;
  @apply text-14p;
  @apply cursor-pointer;
  @apply select-none;
  @apply p-8p;
}
.filtered-item:hover{
  @apply bg-blue-light;
  @apply text-white;
}
</style>

