<template>
   <span class="text-14p text-blue-dark whitespace-nowrap select-none">
               Toon
     <label for="" class="inline-block relative rounded-4p">
         <select name="" id="" v-model="Model" class="appearance-none text-12p rounded-4p pl-4p py-5p bg-gray-light-20 pr-20p">
          <option :value="25">25</option>
          <option :value="50">50</option>
          <option :value="75">75</option>
          <option :value="100">100</option>
   </select>

       <div class="absolute top-4p right-6p pointer-events-none">
         <font-awesome-icon icon="chevron-down" class="text-12p "/>
       </div>
     </label>
    van {{ $store.getters["DossierModule/getTotalFilteredDossiers"] }} resultaten
            </span>
</template>

<script>
export default {
  name: "VlIndexPagination",
  computed: {
    Model: {
      get() {
        return this.$store.state.DossierModule.PaginationModule.paginated.amount
      },
      set(value) {
        this.$store.dispatch('DossierModule/PaginationModule/setAmount', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
