import Vue from 'vue'
import Vuex from 'vuex'
import {DossierModule} from "@/store/_dossiers.module";
import {DataModule} from "@/store/_data.module";
import {DossierDetailModule} from "@/store/_dossier_detail.module";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        showFilterMenu: false,
        showMobileFilterMenu: false,
        overflow: false,
        showCover: false,
        isLoaded: false,
        isMobile: false,
        isTablet: false
    },
    getters: {
        isLoaded: state => state.isLoaded,
        isMobile: state => state.isMobile,
        isTablet: state => !state.isMobile && state.isTablet,
        isScreen: state => !state.isMobile && !state.isTablet
    },
    mutations: {
        SET_FILTER_MENU: (state, value) => state.showFilterMenu = value,
        SET_MOBILE_FILTER_MENU: (state, value) => state.showMobileFilterMenu = value,
        SET_OVERFLOW: (state, value) => state.overflow = value,
        SET_COVER: (state, value) => state.showCover = value,
        SET_LOADED: (state, value) => state.isLoaded = value,
        SET_IS_MOBILE: (state, value) => state.isMobile = value,
        SET_IS_TABLET: (state, value) => state.isTablet = value
    },
    actions: {
        load: ({dispatch}) => {
            Promise
                .all([
                    dispatch('DataModule/getGeneralInformation'),
                    dispatch('DataModule/getResults'),
                    dispatch('DataModule/getTemplates')
                ])
        },
        toggleFilterMenu: ({commit, state}) => {
            const currentState = state.showFilterMenu
            commit('SET_FILTER_MENU', !currentState)
            commit('SET_OVERFLOW', !currentState)
            commit('SET_COVER', !currentState)
        },
        toggleMobileFilterMenu: ({commit, state}) => {
            const currentState = state.showMobileFilterMenu
            commit('SET_MOBILE_FILTER_MENU', !currentState)
            commit('SET_OVERFLOW', !currentState)
        },
        toggleModal: ({commit}, payload) => {
            commit('SET_OVERFLOW', payload)
            commit('SET_COVER', payload)
        },
        setLoaded: ({commit}, payload) => {
            commit('SET_LOADED', payload)
        },
        setIsMobile: ({commit}, payload) => commit('SET_IS_MOBILE', payload),
        setIsTablet: ({commit}, payload) => commit('SET_IS_TABLET', payload),
    },
    modules: {
        DossierModule,
        DataModule,
        DossierDetailModule
    }
})
