export default class Address {
    city_name = ''
    city_postcode = ''
    street_name = ''
    street_number = ''
    street_number_addition = ''

    setAddress(data){
        if (data.city_name) this.city_name = data.city_name
        if (data.city_postcode) this.city_postcode = data.city_postcode
        if (data.street_name) this.street_name = data.street_name
        if (data.street_number){

            if (!isNaN(data.street_number)){
                const parsed = parseInt(data.street_number)
                this.street_number = parsed
                this.street_number_addition = data.street_number.replace(parsed, '')
            }else {
                this.street_number_addition = data.street_number
            }

        }
    }
}
