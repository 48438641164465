<template>
  <div id="app" class="relative min-h-screen">
    <nav>
      <vl-navbar></vl-navbar>
    </nav>
    <vl-fade-animation>
      <main v-show="$store.getters.isLoaded" class="overflow-y-auto">
        <router-view :key="$route.fullPath"/>
      </main>
    </vl-fade-animation>

    <vl-fade-animation>
      <vl-cover v-show="$store.state.showCover"/>
    </vl-fade-animation>

    <vl-fade-animation>
      <vl-loading-screen v-show="ShowLoadingScreen"/>
    </vl-fade-animation>
  </div>
</template>
<script>
import VlNavbar from "@/components/VlNavbar";
import VlCover from "@/components/Elements/VlCover";
import VlFadeAnimation from "@/components/Animations/VlFadeAnimation";
import VlLoadingScreen from "@/components/VlLoadingScreen";
import * as _ from 'lodash'

export default {
  components: {
    VlNavbar,
    VlCover,
    VlFadeAnimation,
    VlLoadingScreen
  },
  watch: {
    Overflow: {
      handler: function (val) {
        if (val === true) {
          document.getElementsByTagName("body")[0].classList.add('h-screen', 'overflow-hidden')
        }

        if (val === false) {
          document.getElementsByTagName("body")[0].classList.remove('h-screen', 'overflow-hidden')

        }
      }
    }
  },
  computed: {
    Overflow() {
      return this.$store.state.overflow
    },
    ShowLoadingScreen() {
      return !this.$store.getters.isLoaded
    },
  },
  methods:{
    setSize(){
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      this.$store.dispatch('setIsMobile', window.innerWidth < 1024)
      this.$store.dispatch('setIsTablet', window.innerWidth >= 1024 && window.innerWidth < 1536)
    }
  },
  created() {
    this.setSize()
    window.addEventListener('resize', _.throttle(() => {
      this.setSize()
    }, 300))
  }
}
</script>
<style lang="scss">
</style>
