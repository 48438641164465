import dayjs from "dayjs";

export const FILTERS = {
    SEARCH: 'search',
    TYPE: 'type',
    RESULT: 'result',
    LAST_ACTION: 'last_action',
    EXAMINATION_DATE: 'examination_date',
    DEADLINE: 'deadline',
    DEADLINE_PASSED: 'deadline_passed',
    CITY: 'city',
    STREET: 'street',
    ONLY_WITH_NEW_EXAMINATION: 'only_with_new_examination',
    ONLY_WITHOUT_NEW_EXAMINATION: 'only_without_new_examination',
    MEASURE_CHAPTER: 'measure_chapter',
    MEASURE: 'measure',
    NO_EXAMINATION: 'no_examination',
    FROM_BUILDING_PERMIT: 'from_building_permit',
    FROM_DISCONNECTION_PROJECT_BUILDING: 'from_disconnection_project_building',
    DOSSIER_CREATED_AT: 'dossier_created_at'
}

export const FilterModule = {
    namespaced: true,
    state: {
        filters: {
            search: '',
            type: [],
            result: [],
            last_action: [],
            examination_date: {
                from: null,
                to: null
            },
            deadline: {
                from: null,
                to: null
            },
            deadline_passed: false,
            city: [],
            street: [],
            only_with_new_examination: false,
            only_without_new_examination: false,
            measure_chapter: '',
            measure: '',
            no_examination: true,
            from_building_permit: false,
            from_disconnection_project_building: false,
            dossier_created_at: {
                from: null,
                to: null
            }
        }
    },
    getters: {
        getSearchFilter: (state) => state.filters.search.toLowerCase(),
        getSelectedFilters: (state, getters) => {
            return [
                ...getters.selectedItemsFilter,
                ...getters.selectedResultsFilter,
                ...getters.selectedLastActionsFilter,
                ...getters.selectedExaminationDateFilter,
                ...getters.selectedDeadlineFilter,
                ...getters.selectedDeadlinePassedFilter,
                ...getters.selectedCityFilter,
                ...getters.selectedStreetFilter,
                ...getters.getMeasureFilter,
                ...getters.getNewExaminationFilters,
                ...getters.getDossierWithoutExaminationFilters
            ]
        },
        selectedItemsFilter: (state) => {
            const filters = []

            if (state.filters.type.length !== 0) {
                state.filters.type.forEach((item) => {
                    filters.push({
                        label: item.code,
                        filter: FILTERS.TYPE,
                        item: item
                    })
                })
            }
            return filters
        },
        selectedResultsFilter: (state) => {
            const filters = []

            if (state.filters.result.length !== 0) {
                state.filters.result.forEach((item) => {
                    filters.push({
                        label: item,
                        filter: FILTERS.RESULT,
                        item: item
                    })
                })
            }

            return filters
        },
        selectedLastActionsFilter: (state) => {
            const filters = []

            if (state.filters.last_action.length !== 0) {
                state.filters.last_action.forEach((item) => {
                    filters.push({
                        label: item.name,
                        filter: FILTERS.LAST_ACTION,
                        item: item.id
                    })
                })
            }
            return filters
        },
        selectedExaminationDateFilter: (state) => {
            const filters = []

            if (state.filters.examination_date.from && state.filters.examination_date.to) {
                const from = formatDate(state.filters.examination_date.from)
                const to = formatDate(state.filters.examination_date.to)
                filters.push({
                    label: `Keuring: ${from} - ${to}`,
                    filter: FILTERS.EXAMINATION_DATE,
                    item: null
                })
            }
            return filters
        },
        selectedDeadlineFilter: (state) => {
            const filters = []

            if (state.filters.deadline.from && state.filters.deadline.to) {
                const from = dayjs(state.filters.deadline.from).format('DD/MM')
                const to = dayjs(state.filters.deadline.to).format('DD/MM')
                filters.push({
                    label: `Deadline: ${from} - ${to}`,
                    filter: FILTERS.DEADLINE,
                    item: null
                })
            }
            return filters
        },
        selectedDeadlinePassedFilter: (state) => {
            const filters = []

            if (state.filters.deadline_passed) {
                filters.push({
                    label: 'Verstreken deadlines',
                    filter: FILTERS.DEADLINE_PASSED,
                    item: null
                })
            }
            return filters
        },
        selectedCityFilter: state => {
            const filters = []

            if (state.filters.city.length !== 0) {
                state.filters.city.forEach(city => {
                    filters.push({
                        label: city,
                        filter: FILTERS.CITY,
                        item: city
                    })
                })
            }

            return filters
        },

        selectedStreetFilter: state => {
            const filters = []

            if (state.filters.street.length !== 0) {
                state.filters.street.forEach(street => {
                    filters.push({
                        label: street,
                        filter: FILTERS.STREET,
                        item: street
                    })
                })
            }

            return filters
        },
        getNewExaminationFilters: (state) => {
            const filters = []
            if (state.filters.only_with_new_examination) {
                filters.push({
                    label: 'Enkel met nieuwe keuringen',
                    filter: FILTERS.ONLY_WITH_NEW_EXAMINATION,
                    item: false
                })
            }

            if (state.filters.only_without_new_examination) {
                filters.push({
                    label: 'Enkel zonder nieuwe keuringen',
                    filter: FILTERS.ONLY_WITHOUT_NEW_EXAMINATION,
                    item: false
                })
            }

            return filters
        },
        getMeasureFilter: (state, getters, rootState, rootGetters) => {
            const filters = []

            if (state.filters.measure_chapter !== '') {
                const chapter = rootGetters['DataModule/getMeasureById'](state.filters.measure_chapter)

                filters.push({
                    label: `${chapter.code}`,
                    filter: FILTERS.MEASURE_CHAPTER,
                    item: null
                })

                if (state.filters.measure !== '') {
                    const measure = chapter.measures.find(measure => measure.id === state.filters.measure)
                    filters.push({
                        label: `${measure.code}`,
                        filter: FILTERS.MEASURE,
                        item: null
                    })
                }
            }


            return filters
        },
        getDossierWithoutExaminationFilters: (state) => {
            const filters = []

            if (!state.filters.no_examination) {
                filters.push({
                    label: 'Toon dossiers zonder keuring',
                    filter: FILTERS.NO_EXAMINATION,
                    item: true
                })
            }

            if (state.filters.from_building_permit) {
                filters.push({
                    label: 'Vanuit module omgevingsvergunning',
                    filter: FILTERS.FROM_BUILDING_PERMIT,
                    item: false
                })
            }

            if (state.filters.from_disconnection_project_building) {
                filters.push({
                    label: 'Vanuit module afkoppeling',
                    filter: FILTERS.FROM_DISCONNECTION_PROJECT_BUILDING,
                    item: false
                })
            }

            if (state.filters.dossier_created_at.from && state.filters.dossier_created_at.to) {
                const from = dayjs(state.filters.dossier_created_at.from).format('DD/MM')
                const to = dayjs(state.filters.dossier_created_at.to).format('DD/MM')
                filters.push({
                    label: `Aangemaakt op: ${from} - ${to}`,
                    filter: FILTERS.DOSSIER_CREATED_AT,
                    item: null
                })
            }

            return filters
        }
    },
    mutations: {
        UPDATE_FILTER: (state, payload) => {
            const location = payload.filter.split('.')

            if (location.length === 1) {
                state.filters[location[0]] = payload.data
            }

            if (location.length === 2) {
                state.filters[location[0]][location[1]] = payload.data
            }
        }
    },
    actions: {
        updateFilter: ({commit}, payload) => {
            commit('UPDATE_FILTER', payload)
        },
        updateCityFilter: ({state, commit}, payload) => {
            const hasFilter = state.filters.city.filter(e => e === payload).length === 1
            let data;
            if (hasFilter) {
                data = state.filters.city.filter(e => e !== payload)
            } else {
                data = [...state.filters.city, payload]
            }

            commit('UPDATE_FILTER', {
                filter: FILTERS.CITY,
                data: data
            })
        },
        updateStreetFilter: ({state, commit}, payload) => {
            const hasFilter = state.filters.street.filter(e => e === payload).length === 1
            let data;
            if (hasFilter) {
                data = state.filters.street.filter(e => e !== payload)
            } else {
                data = [...state.filters.street, payload]
            }
            commit('UPDATE_FILTER', {
                filter: FILTERS.STREET,
                data: data
            })
        },

        // Wis alle filters
        clearAllFilters: ({dispatch}) => {
            dispatch('clearTypeFilter')
            dispatch('clearResultFilter')
            dispatch('clearLastActionFilter')
            dispatch('clearExaminationDateFilter')
            dispatch('clearDeadlineFilter')
            dispatch('clearMeasureChapterFilter')
            dispatch('clearNoExaminationFilter')
            dispatch('clearFromBuildingPermitFilter')
            dispatch('clearFromDisconnectionProjectBuilding')
            dispatch('clearDeadlinePassedFilter')
            dispatch('clearCitynameFilter')
            dispatch('clearStreetnameFilter')
            dispatch('clearOnlyWithNewExaminationFilter')
            dispatch('clearOnlyWithoutNewExaminationFilter')
            dispatch('clearDossierCreatedAtFilter')
        },

        // Wis individuele filters
        clearFilter: ({state, commit, dispatch}, payload) => {

            if (payload.filter === FILTERS.TYPE) {
                const data = state.filters.type.filter(e => e !== payload.item)
                dispatch('updateFilter', {
                    filter: FILTERS.TYPE,
                    data: data
                })
            }

            if (payload.filter === FILTERS.RESULT) {
                const data = state.filters.result.filter(e => e !== payload.item)
                commit('UPDATE_FILTER', {
                    filter: FILTERS.RESULT,
                    data: data
                })
            }

            if (payload.filter === FILTERS.LAST_ACTION) {
                const data = state.filters.last_action.filter(e => e.id !== payload.item)
                commit('UPDATE_FILTER', {
                    filter: FILTERS.LAST_ACTION,
                    data: data
                })
            }

            if (payload.filter === FILTERS.CITY) {
                const data = state.filters.city.filter(e => e !== payload.item)
                commit('UPDATE_FILTER', {
                    filter: FILTERS.CITY,
                    data: data
                })
            }

            if (payload.filter === FILTERS.STREET) {
                const data = state.filters.street.filter(e => e !== payload.item)
                commit('UPDATE_FILTER', {
                    filter: FILTERS.STREET,
                    data: data
                })
            }

            if (payload.filter === FILTERS.EXAMINATION_DATE) dispatch('clearExaminationDateFilter')
            if (payload.filter === FILTERS.DEADLINE) dispatch('clearDeadlineFilter')
            if (payload.filter === FILTERS.DEADLINE_PASSED) dispatch('clearDeadlinePassedFilter')

            if (payload.filter === FILTERS.ONLY_WITH_NEW_EXAMINATION) dispatch('clearOnlyWithNewExaminationFilter')
            if (payload.filter === FILTERS.ONLY_WITHOUT_NEW_EXAMINATION) dispatch('clearOnlyWithoutNewExaminationFilter')

            if (payload.filter === FILTERS.MEASURE_CHAPTER) dispatch('clearMeasureChapterFilter')
            if (payload.filter === FILTERS.MEASURE) dispatch('clearMeasureFilter')

            if (payload.filter === FILTERS.NO_EXAMINATION) dispatch('clearNoExaminationFilter')
            if (payload.filter === FILTERS.FROM_BUILDING_PERMIT) dispatch('clearFromBuildingPermitFilter')
            if (payload.filter === FILTERS.FROM_DISCONNECTION_PROJECT_BUILDING) dispatch('clearFromDisconnectionProjectBuilding')

            if (payload.filter === FILTERS.DOSSIER_CREATED_AT) dispatch('clearDossierCreatedAtFilter')

        },
        clearTypeFilter: ({dispatch}) => dispatch('updateFilter', {filter: FILTERS.TYPE, data: []}),
        clearResultFilter: ({dispatch}) => dispatch('updateFilter', {filter: FILTERS.RESULT, data: []}),
        clearLastActionFilter: ({dispatch}) => dispatch('updateFilter', {filter: FILTERS.LAST_ACTION, data: []}),
        clearExaminationDateFilter: ({dispatch}) => dispatch('updateFilter', {
            filter: FILTERS.EXAMINATION_DATE,
            data: {from: null, to: null}
        }),
        clearDeadlineFilter: ({dispatch}) => dispatch('updateFilter', {
            filter: FILTERS.DEADLINE,
            data: {from: null, to: null}
        }),
        clearDeadlinePassedFilter: ({dispatch}) => dispatch('updateFilter', {
            filter: FILTERS.DEADLINE_PASSED,
            data: false
        }),
        clearOnlyWithNewExaminationFilter: ({dispatch}) => {
            dispatch('updateFilter', {
                filter: FILTERS.ONLY_WITH_NEW_EXAMINATION,
                data: false
            })
        },
        clearOnlyWithoutNewExaminationFilter: ({dispatch}) => {
            dispatch('updateFilter', {
                filter: FILTERS.ONLY_WITHOUT_NEW_EXAMINATION,
                data: false
            })
        },
        clearMeasureChapterFilter: ({dispatch}) => {
            dispatch('updateFilter', {
                filter: FILTERS.MEASURE_CHAPTER,
                data: ''
            })

            dispatch('updateFilter', {
                filter: FILTERS.MEASURE,
                data: ''
            })
        },
        clearMeasureFilter: ({dispatch}) => {
            dispatch('updateFilter', {
                filter: FILTERS.MEASURE,
                data: ''
            })
        },
        clearNoExaminationFilter: ({dispatch}) => {
            dispatch('updateFilter', {
                filter: FILTERS.NO_EXAMINATION,
                data: true
            })
        },
        clearFromBuildingPermitFilter: ({dispatch}) => {
            dispatch('updateFilter', {
                filter: FILTERS.FROM_BUILDING_PERMIT,
                data: false
            })
        },
        clearFromDisconnectionProjectBuilding: ({dispatch}) => {
            dispatch('updateFilter', {
                filter: FILTERS.FROM_DISCONNECTION_PROJECT_BUILDING,
                data: false
            })
        },
        clearDossierCreatedAtFilter: ({dispatch}) => dispatch('updateFilter', {
            filter: FILTERS.DOSSIER_CREATED_AT,
            data: {from: null, to: null}
        }),
        clearCitynameFilter: ({dispatch}) => {
            dispatch('updateFilter', {
                filter: FILTERS.CITY,
                data: []
            })
        },
        clearStreetnameFilter: ({dispatch}) => {
            dispatch('updateFilter', {
                filter: FILTERS.STREET,
                data: []
            })
        }
    },
}

function formatDate(date) {
    if (isNumeric(date)) {
        return dayjs.unix(date).format('DD/MM')
    } else {
        return dayjs(date).format('DD/MM')
    }
}

function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}
