<template>
  <div class="border-b border-solid border-gray-border mb-20p">
    <vl-label label="Gegevens"/>
    <div class="vl-modal-dossier-data-grid pb-20p">
      <vl-modal-display-data label="Dossiernummer" :value="ActiveDossierNumber | nonEmpty"/>
      <vl-modal-display-data label="Type" :value="ActiveDossierType | nonEmpty"/>
      <vl-modal-display-data label="Laatste actie" :value="ActiveDossierLastAction | nonEmpty"/>
      <vl-modal-display-data label="Deadline" :value="ActiveDossierLastActionDeadline | nonEmpty"/>
    </div>
  </div>
</template>

<script>
import VlLabel from "@/components/Elements/Form/Elements/VlLabel";
import VlModalDisplayData from "@/components/Elements/Modal/Elements/VlModalDisplayData";

export default {
  name: "VlModalDossierData",
  components: {
    VlModalDisplayData,
    VlLabel
  },
  props: {
    activeDossierNumber:{
      type: String,
      default: ''
    },
    activeDossierType: {
      type: String,
      default: ''
    },
    activeDossierLastAction:{
      type: String,
      default: ''
    },
    activeDossierLastActionDeadline:{
      type: String,
      default: ''
    }
  },
  computed: {
    ActiveDossierNumber() {
      return this.activeDossierNumber
    },
    ActiveDossierType() {
      return this.activeDossierType
    },
    ActiveDossierLastAction() {
      return this.activeDossierLastAction
    },
    ActiveDossierLastActionDeadline() {
      return this.activeDossierLastActionDeadline
    },
  }
}
</script>

<style lang="scss">
.vl-modal-dossier-data-grid{
  display: grid;
  grid-template-columns: 1fr 120px;
grid-gap: 8px;
  @screen lg{
    grid-template-columns: 180px 80px 1fr 120px;
  }
}
</style>
