<template>
  <label :for="`${id_prefix}_checkbox_${id}`" class="grid cursor-pointer" @click="$emit('click')">
    <div class="place-self-center">
      <div class="w-14p h-14p relative bg-blue-light rounded-3p relative" v-show="isChecked">
        <div class="absolute w-14p" style="top:50%; left:50%; transform: translate(-50%, -50%)">
          <span class="text-white inline-block text-10p pl-2p pb-5p">
            <font-awesome-icon icon="check"/>
          </span>
        </div>
      </div>
      <div class=" w-14p h-14p rounded-3p bg-white shadow-checkbox-inner border border-solid border-gray-cover"
           v-show="!isChecked"></div>
    </div>
  </label>
</template>

<script>
import {randomNumber} from "@/mixins/RandomNumber.mixin";

export default {
  name: "VlIndexCheckbox",
  watch: {
    value: {
      handler: function () {
        this.onChange()
      }
    },
    Model: {
      handler: function () {
        this.onChange()
      }
    }
  },
  props: {
    id_prefix: {
      type: String,
      required: false,
      default: ''
    },
    isChecked: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      id: randomNumber(5000)
    }
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
