<template>
  <transition
      @before-enter="beforeEnter"
      @enter="enter"
      @leave="leave"
      :css="false"
      mode="out-in">
    <slot></slot>
  </transition>
</template>

<script>
import {gsap} from "gsap";

export default {
  name: "VlFadeAnimation",
  methods: {
    beforeEnter(el) {
      gsap.set(el, {opacity: 0, clearProps: 'all'})
    },
    enter(el, done) {
      gsap.to(el, {opacity: 1, duration: .3, clearProps: 'all', onComplete:() => done()})
    },
    leave(el, done) {
      gsap.to(el, {opacity: 0, duration: .3, clearProps: 'all', onComplete: () => done()})
    }
  }
}
</script>

<style>

</style>
