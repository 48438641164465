<template>
  <div class="grid grid-cols-min-1fr-min gap-8p">
    <div class="cursor-pointer">
      <span @click="previousPage" class="text-14p 2xl:text-16p whitespace-nowrap" :class="[IsFirstPage? 'text-gray-light' : 'text-blue-dark']" v-show="NumberOfPages > 1">
        <span class="inline-block mr-8p text-12p 2xl:text-14p"><font-awesome-icon icon="chevron-left" /></span>Vorige pagina</span>
    </div>
    <div class="text-14p">
      <vl-pagination-item @click="setPage(1)" :value="1" :active="CurrentPage === 1"/>
      <span class="text-14p 2xl:text-16p text-blue-dark mr-8p" v-if="HasMorePreviousPages">...</span>

      <vl-pagination-item
          @click="setPage(page)"
          :value="page"
          :active="CurrentPage === page"
          :key="`pagination_${page}`"
          v-for="page in DisplayPages"/>

      <span class="text-14p 2xl:text-16p text-blue-dark mr-8p" v-if="HasMoreNextPages">...</span>
      <vl-pagination-item @click="setPage(LastPage)" :value="LastPage" :active="CurrentPage === LastPage" v-show="NumberOfPages > 1"/>
    </div>
    <div class="cursor-pointer">
      <span @click="nextPage" class="text-14p 2xl:text-16p whitespace-nowrap" :class="[IsLastPage ? 'text-gray-light' : 'text-blue-dark' ]" v-show="NumberOfPages > 1">Volgende pagina <span class="inline-block ml-8p text-12p 2xl:text-14p"><font-awesome-icon icon="chevron-right" /></span></span>
    </div>
  </div>
</template>

<script>
import VlPaginationItem from "@/components/Index/Pagination/VlPaginationItem";

const DISPLAY_PAGES = 2

export default {
  name: "VlIndexPagination",
  components: {
    VlPaginationItem
  },
  computed: {
    CurrentPage() {
      return this.$store.getters["DossierModule/PaginationModule/getCurrentPage"]
    },
    AvailablePages() {
      if (this.LastPage === 0 || this.LastPage === 1) {
        return []
      }

      return Array.from(Array((this.LastPage - 2)), (x, index) => 2 + index)
    },
    CurrentAvailablePage() {
      return this.CurrentPage - 2
    },
    DisplayPages() {
      if (!this.HasMorePreviousPages) return this.AvailablePages.slice(0, DISPLAY_PAGES * 2)
      if (!this.HasMoreNextPages) return this.AvailablePages.slice(-(DISPLAY_PAGES * 2))

      const previousPages = this.AvailablePages.slice(this.CurrentAvailablePage - DISPLAY_PAGES, this.CurrentAvailablePage)
      const nextPages = this.AvailablePages.slice(this.CurrentAvailablePage + 1, (this.CurrentAvailablePage + 1 + DISPLAY_PAGES))

      return [...previousPages, this.CurrentPage, ...nextPages]
    },
    HasMorePreviousPages() {
      return (this.CurrentPage - DISPLAY_PAGES) > 1
    },
    HasMoreNextPages() {
      return (this.CurrentPage + DISPLAY_PAGES) < this.LastPage
    },
    LastPage() {
      return this.NumberOfPages
    },
    NumberOfPages() {
      return this.$store.getters["DossierModule/PaginationModule/getNumberOfPages"]
    },
    IsFirstPage() {
      return this.CurrentPage === 1
    },
    IsLastPage() {
      return this.CurrentPage === this.LastPage
    }
  },
  methods: {
    previousPage() {
      if (!this.IsFirstPage) {
        this.$store.dispatch('DossierModule/PaginationModule/setPage', this.CurrentPage - 1)
      }
    },
    setPage(page) {
      this.$store.dispatch('DossierModule/PaginationModule/setPage', page)
    },
    nextPage() {
      if (!this.IsLastPage) {
        this.$store.dispatch('DossierModule/PaginationModule/setPage', this.CurrentPage + 1)
      }
    }
  }
}
</script>

<style scoped>

</style>
