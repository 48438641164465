import dayjs from "@/dayjs";

export default class CreateAction {
    action_id = null;
    executed_at = null;
    deadline = null;
    comment;

    /**
     * @param {{action_id: number, executed_at: Date, deadline: Date, comment: string }} data
     */
    constructor(data) {
        this.action_id = data.action_id;
        this.deadline = data.deadline;
        this.executed_at = data.executed_at;
        this.comment = data.comment;
    }

    /**
     * return boolean
     */
    isValid() {
        if (!this.action_id) return false
        if (!this.executed_at) return false

        return true
    }

    /**
     * @return {FormData}
     */
    preparePost() {
        const formData = new FormData()
        formData.set('action_id', this.action_id)
        formData.set('executed_at', dayjs(this.executed_at).utc(true).local().toISOString())
        formData.set('deadline', this.deadline ? dayjs(this.deadline).utc(true).local().toISOString() : '')
        formData.set('comment', this.comment)
        return formData
    }
}
