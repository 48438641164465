<template>
  <div>
    <vl-filter-template label="Type keuring" :open="open" :toggleable="toggleable" @clear-filter="$store.dispatch('DossierModule/FilterModule/clearTypeFilter')">
      <div class="grid gap-2 grid-cols-3">
        <vl-checkbox id_prefix="type" :key="`type_${item.code}_${item.id}`" v-model="Model" :model-value="item" :label="item.code" v-for="item in $store.getters['DataModule/getTypes']"/>
      </div>
    </vl-filter-template>

  </div>
</template>

<script>
import VlFilterTemplate from "@/components/Elements/Filters/VlFilterTemplate";
import VlCheckbox from "@/components/Elements/Form/Elements/VlCheckbox";

export default {
  name: "VlTypeOfExaminationFilter",
  components: {
    VlFilterTemplate,
    VlCheckbox
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: true
    },
    toggleable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      showData: false
    }
  },
  computed: {
    Model: {
      get() {
        return this.$store.state.DossierModule.FilterModule.filters.type
      },
      set(value) {
        this.$store.dispatch('DossierModule/FilterModule/updateFilter', {
          filter: 'type',
          data: value
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
