<template>
  <div>
    <vl-mobile-filter-template label="Herstelmaatregelen" :open="open" :toggleable="toggleable"
                               @clear-filter="clearFilter">
      <div class="mb-8p">
        <vl-select v-model="ModelChapters"
                   :values="Chapters"
                   label="Selecteer een hoofdstuk"
        />
      </div>
      <div>
        <vl-select v-model="ModelMeasures"
                   :values="Measures"
                   label="Selecteer een herstelmaatregel"
        />
      </div>
    </vl-mobile-filter-template>
  </div>
</template>

<script>
import VlMobileFilterTemplate from "@/components/Elements/Filters/VlFilterTemplate";
import VlSelect from "@/components/Elements/Form/Elements/VlSelect";
import {FILTERS} from "@/store/_dossiers_filters.module";

export default {
  name: "VlMeasuresFilter",
  components: {
    VlMobileFilterTemplate,
    VlSelect
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: true
    },
    toggleable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ModelChapters: {
      get() {
        return this.$store.state.DossierModule.FilterModule.filters.measure_chapter
      },
      set(value) {
        this.clearFilter()

        this.$store.dispatch('DossierModule/FilterModule/updateFilter',
            {
              filter: FILTERS.MEASURE_CHAPTER,
              data: value
            })
      }
    },
    ModelMeasures: {
      get() {
        return this.$store.state.DossierModule.FilterModule.filters.measure
      },
      set(value) {
        if (!value) value = ''

        this.$store.dispatch('DossierModule/FilterModule/updateFilter',
            {
              filter: FILTERS.MEASURE,
              data: value
            })
      }
    },
    Chapters() {
      return this.$store.getters["DataModule/getMeasures"].map(chapter => {
        return {
          id: chapter.id,
          label: `${chapter.code} | ${chapter.name}`
        }
      })
    },
    Measures() {
      if (this.ModelChapters === '') return []

      const chapter = this.$store.getters["DataModule/getMeasures"]
          .find(chapter => chapter.id === this.ModelChapters)

      return chapter.measures.map(measure => {
        return {
          id: measure.id,
          label: `${measure.code} | ${measure.name}`
        }
      })
    }
  },
  data() {
    return {
      chapter: ''
    }
  },
  methods: {
    clearFilter() {
      this.$store.dispatch('DossierModule/FilterModule/clearMeasureChapterFilter')
    }
  }
}
</script>

<style scoped>

</style>
