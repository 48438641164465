import VlModalHeader from "@/components/Elements/Modal/Elements/VlModalHeader";
import VlModalShowFile from "@/components/Elements/Modal/Elements/VlModalShowFile";
import VlModalDisplayData from "@/components/Elements/Modal/Elements/VlModalDisplayData";
import VlModalDossierData from "@/components/Elements/Modal/Elements/VlModalDossierData";
import VlModalTemplate from "@/components/Elements/Modal/Elements/VlModalTemplate";
import VlModalSelectActionType from "@/components/Elements/Modal/Elements/VlModalSelectActionType";
import VlModalSelectDeadline from "@/components/Elements/Modal/Elements/VlModalSelectDeadline";
import VlModalComment from "@/components/Elements/Modal/Elements/VlModalComment";
import VlModalExecutedDate from "@/components/Elements/Modal/Elements/VlModalExecutedDate";
import VlModalAddFiles from '@/components/Elements/Modal/Elements/VlModalAddFiles'
import VlModalParticipant from '@/components/Elements/Modal/Elements/VlModalParticipant'
import VlModalActionAttachment from  '@/components/Elements/Modal/Elements/VlModalActionAttachment'

const ModalElements = {
    VlModalHeader,
    VlModalShowFile,
    VlModalDisplayData,
    VlModalDossierData,
    VlModalTemplate,
    VlModalSelectActionType,
    VlModalSelectDeadline,
    VlModalComment,
    VlModalExecutedDate,
    VlModalAddFiles,
    VlModalParticipant,
    VlModalActionAttachment
}

export default ModalElements
