<template>
  <div class="mb-20p">
    <vl-select label="Welk type actie wil je toevoegen?"
               :values="Values" v-model="Model"/>
  </div>
</template>

<script>
import VlSelect from "@/components/Elements/Form/Elements/VlSelect";

export default {
  name: "VlModalSelectActionType",
  components: {
    VlSelect
  },
  props: {
    value: {
      required: true
    }
  },
  computed: {
    Model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    Values() {
      return this.$store.getters['DataModule/getActions'].map(action => {
        return {
          id: action.id,
          label: `${action.name}`
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
