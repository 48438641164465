<template>
  <div>
    <vl-modal-template>
      <template v-slot:header>
        <vl-modal-header title="Genereer template(s)">
          <span class="text-14p font-600 text-blue-dark cursor-pointer select-none"
                @click="$emit('close')">

                    <span class="inline-block mr-4p">
            <font-awesome-icon icon="times"/>
          </span>
            Sluiten
          </span>
        </vl-modal-header>
      </template>

      <template>
        <section v-if="isLoading">
          <div class="flex justify-center">
           <div>
             <span class="text-14p text-blue-dark inline-block mr-4p"><font-awesome-icon icon="circle-notch" class="fa-spin" /></span>
               <span class="text-14p text-blue-dark">
                We zijn de templates aan het genereren.
            </span>
           </div>
          </div>
        </section>

        <section v-if="!isLoading">
          <vl-modal-dossier-data
              :active-dossier-number="ActiveDossierNumber"
              :active-dossier-type="ActiveDossierType"
              :active-dossier-last-action="ActiveDossierLastAction"
              :active-dossier-last-action-deadline="ActiveDossierLastActionDeadline"/>
        </section>

        <section v-if="!isLoading" class="pr-16p lg:pr-0">
          <vl-display-template :dossier="ActiveDossier"/>
        </section>
      </template>


      <template v-slot:footer>
        <div class="grid grid-cols-3" v-if="!isLoading">
          <div @click="previousDossier" class="text-14p cursor-pointer select-none"
               :class="[FirstDossier ? 'text-gray-light' : 'text-blue-dark']"><span
              class="inline-block text-12p mr-4p"><font-awesome-icon icon="chevron-left"/></span>Vorig dossier
          </div>
          <div class="justify-self-center select-none"><span class="text-14p">{{ CurrentDossier }}/{{
              TotalDossiers
            }}</span></div>
          <div class="justify-self-end">
            <div @click="nextDossier" class=" text-14p cursor-pointer select-none"
                 :class="[LastDossier ? 'text-gray-light' : 'text-blue-dark']">Volgend dossier
              <span class="inline-block text-12p ml-4p"><font-awesome-icon icon="chevron-right"/></span></div>
          </div>
        </div>
      </template>

    </vl-modal-template>
  </div>
</template>

<script>
import VlDisplayTemplate from '@/components/Elements/Modal/Template/VlDisplayTemplate'
import ModalElements from "@/components/Elements/Modal/Elements/ModalElements";
import * as APIService from '@/services/ApiService'
import DossierDetail from "@/services/models/DossierDetail";

export default {
  name: "VlGenerateTemplatesModal",
  components: {
    VlModalTemplate: ModalElements.VlModalTemplate,
    VlModalHeader: ModalElements.VlModalHeader,
    VlDisplayTemplate,
    VlModalDossierData: ModalElements.VlModalDossierData,
  },
  data() {
    return {
      placeholderText: `<span style="color:#B2C0C9">|zelf in te vullen|</span>`,
      selected_dossiers: [],
      active_dossier: 0,
      isLoading: false
    }
  },
  computed: {
    HasActiveDossier() {
      return this.ActiveDossier !== undefined
    },
    ActiveDossier() {
      return this.selected_dossiers[this.active_dossier]
    },
    TotalDossiers() {
      return this.selected_dossiers.length
    },
    CurrentDossier() {
      return this.active_dossier + 1
    },
    ActiveDossierNumber() {
      if (!this.HasActiveDossier) return ''
      return this.ActiveDossier.examination.dossier_number
    },
    ActiveDossierType() {
      if (!this.HasActiveDossier) return ''
      return this.ActiveDossier.examination.type
    },
    ActiveDossierLastAction() {
      if (!this.HasActiveDossier) return ''
      return this.ActiveDossier.last_action.action
    },
    ActiveDossierLastActionDeadline() {
      if (!this.HasActiveDossier) return ''
      return this.ActiveDossier.last_action.deadline_formatted
    },
    FirstDossier() {
      return this.active_dossier === 0
    },
    LastDossier() {
      return this.active_dossier === (this.selected_dossiers.length - 1)
    }
  },
  methods: {
    previousDossier() {
      if (!this.FirstDossier) this.active_dossier--
    },
    nextDossier() {
      if (!this.LastDossier) this.active_dossier++
    },
    async fetchDossierDetails() {
      const promiseArray = []
      this.$store.getters["DossierModule/getSelectedDossiers"].forEach((dossier) => {
        promiseArray.push(new Promise(resolve => {
          APIService
              .getDossierDetails(dossier.id)
              .then((response) => {

                const responseData = new DossierDetail(response.data)

                let newDossier = {
                  ...dossier,
                  ...responseData
                }

                resolve(newDossier)
              })
        }))
      })

      return Promise.all(promiseArray)
    }
  },
  async created() {
    this.isLoading = true
    this.fetchDossierDetails().then((response) => {
      this.selected_dossiers = response
      this.isLoading = false
    })

  }
}
</script>

<style scoped>

</style>
