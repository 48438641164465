<template>
  <div class="grid grid-cols-1fr-min gap-16p mb-8p">
    <div @click="$emit('click')"><span class="text-14p text-gray-light select-none">{{ label }}</span></div>
    <div @click="$emit('clear-filter')"><span class="text-14p text-blue-light hover:text-blue-light-hover cursor-pointer select-none">wissen</span></div>
  </div>
</template>

<script>
export default {
  name: "VlFilterHeader",
  props: {
    label: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
