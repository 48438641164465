<template>
  <div class="mb-20p">
    <vl-label label="Wanneer is deze actie uitgevoerd?"/>
    <div class="grid grid-cols-2 gap-8p">
      <div><vl-date-picker-large v-model="Model" type="date" :clearable="false"/></div>
      <div><vl-date-picker-large v-model="Model" type="time" :clearable="false" format="HH:mm"/></div>
    </div>
  </div>
</template>

<script>
import VlLabel from "@/components/Elements/Form/Elements/VlLabel";
import VlDatePickerLarge from "@/components/Elements/Form/Elements/VlDatePickerLarge";

export default {
  name: "VlModalExecutedDate",
  components: {
    VlLabel,
    VlDatePickerLarge
  },
  props: {
    value: {
      required: true
    }
  },
  computed: {
    Model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }

}
</script>

<style scoped>

</style>
