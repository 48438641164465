<template>
<div class="fixed w-screen h-screen top-0 left-0 bg-black-20 z-10"></div>
</template>

<script>
export default {
name: "VlCover"
}
</script>

<style scoped>

</style>
