import * as APIService from '@/services/ApiService'
import {DossierInspection} from "@/services/models/DossierInspection";

export const DossierDetailInspectionModule = {
    namespaced: true,
    state: {
        inspection: {},
        displayInspection: false
    },
    getters: {},
    mutations: {
        SET_INSPECTION: (state, payload) => state.inspection = payload,
        SET_DISPLAY_INSPECTION: (state, payload) => state.displayInspection = payload
    },
    actions: {
        fetchInspection: ({commit, dispatch}, payload) => {
            APIService
                .getInspectionDetails(payload)
                .then((response) => {
                    commit('SET_INSPECTION', new DossierInspection(response.data))
                    dispatch('setDisplayInspection', true)
                })
        },
        setInspection: ({commit, dispatch}, payload) => {
            commit('SET_INSPECTION', new DossierInspection(payload))
            dispatch('setDisplayInspection', true)
        },
        setDisplayInspection: ({dispatch, commit}, payload) => {
            dispatch('toggleModal', payload, {root: true})
            commit('SET_DISPLAY_INSPECTION', payload)
        },
    }
}
