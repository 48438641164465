<template>
  <div class="text-center lg:relative">
    <span class="text-18p text-blue-dark select-none">{{ title | nonEmpty }}</span>
    <div class="absolute top-20p right-20p lg:right-0 lg:top-0">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "VlModalHeader",
  props: {
    title: {
      required: true,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
