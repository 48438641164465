export default class Contact {
    name = ''
    email = ''
    phone = ''


    setContact(data) {
        if (data.name) this.name = data.name
        if (data.email) this.email = data.email
        if (data.phone) this.phone = data.phone
    }
}
