import axios from "axios";

const http = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

http.interceptors.response.use((success) => {
        return success;
    },
    (error) => {
        if (error.response.status === 403) window.location.replace(error.response.data.url);
        return Promise.reject(error)
    })

export default http;
