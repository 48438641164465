<template>
  <div class="grid grid-rows-2 relative w-16p">
        <span class="text-14p absolute" :class="UpArrow">
           <font-awesome-icon icon="sort-up"/>
        </span>
    <span class="text-14p absolute" :class="DownArrow">
           <font-awesome-icon icon="sort-down"/>
        </span>
  </div>
</template>

<script>
export default {
  name: "VlSortIcon",
  props:{
    asc:{
      type: Boolean,
      required: false,
      default: false
    },
    desc:{
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed:{
    UpArrow(){
      if (this.asc) return 'text-blue-light'
      return 'text-gray-light-40'
    },
    DownArrow(){
      if (this.desc) return 'text-blue-light'
      return 'text-gray-light-40'
    }
  }
}
</script>

<style scoped>

</style>
