<template>
  <div>
    <vl-mobile-filter-template label="Dossier(s) zonder keuring"
                               :open="open"
                               :toggleable="toggleable"
                               @clear-filter="clearFilters">

      <vl-checkbox label="Niet weergeven" model-value="false" v-model="NoExamination"/>
      <vl-checkbox label="Vanuit module omgevingsvergunning" model-value="false" v-model="FromBuildingPermit"/>
      <vl-checkbox label="Vanuit module afkoppeling" model-value="false" v-model="FromDisconnectionProjectBuilding"/>

      <div class="hidden xl:grid grid-cols-1fr-min-1fr-min gap-8p mt-8p">
        <div>Van</div>
        <vl-date-picker-small v-model="FromDate"/>
        <div>t.e.m</div>
        <vl-date-picker-small v-model="ToDate"/>
      </div>

    </vl-mobile-filter-template>

  </div>
</template>

<script>
import VlMobileFilterTemplate from "@/components/Elements/Filters/VlFilterTemplate";
import VlCheckbox from "@/components/Elements/Form/Elements/VlCheckbox";
import VlDatePickerSmall from "@/components/Elements/Form/Elements/VlDatePickerSmall";

export default {
  name: "VlNoExaminationFilter",
  components: {
    VlMobileFilterTemplate,
    VlCheckbox,
    VlDatePickerSmall
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: true
    },
    toggleable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed:{
    FromDate:{
      get(){
        return this.$store.state.DossierModule.FilterModule.filters.dossier_created_at.from
      },
      set(value){
        this.$store.dispatch('DossierModule/FilterModule/updateFilter', {
          filter: 'dossier_created_at.from',
          data: value
        })
      }
    },
    ToDate:{
      get(){
        return this.$store.state.DossierModule.FilterModule.filters.dossier_created_at.to
      },
      set(value){
        this.$store.dispatch('DossierModule/FilterModule/updateFilter', {
          filter: 'dossier_created_at.to',
          data: value
        })
      }
    },
    NoExamination: {
      get() {
        return this.$store.state.DossierModule.FilterModule.filters.no_examination
      },
      set(value) {
        this.$store.dispatch('DossierModule/FilterModule/updateFilter', {
          filter: 'no_examination',
          data: value
        })
      }
    },
    FromBuildingPermit: {
      get() {
        return this.$store.state.DossierModule.FilterModule.filters.from_building_permit
      },
      set(value) {
        this.$store.dispatch('DossierModule/FilterModule/updateFilter', {
          filter: 'from_building_permit',
          data: value
        })
      }
    },
    FromDisconnectionProjectBuilding: {
      get() {
        return this.$store.state.DossierModule.FilterModule.filters.from_disconnection_project_building
      },
      set(value) {
        this.$store.dispatch('DossierModule/FilterModule/updateFilter', {
          filter: 'from_disconnection_project_building',
          data: value
        })
      }
    },
  },
  methods:{
    clearFilters(){
      this.$store.dispatch('DossierModule/FilterModule/clearNoExaminationFilter')
      this.$store.dispatch('DossierModule/FilterModule/clearFromBuildingPermitFilter')
      this.$store.dispatch('DossierModule/FilterModule/clearFromDisconnectionProjectBuilding')

    }
  }
}
</script>

<style scoped>

</style>
