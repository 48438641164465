import * as ApiService from "@/services/ApiService";
// import router from "@/router";
import * as _ from 'lodash'
import {DossierDetailInspectionModule} from "@/store/_dossier_detail_inspection.module";
import {DateHelper} from "@/mixins/formatDate";
import DossierDetail from "@/services/models/DossierDetail";
import dayjs from "dayjs";

export const DossierDetailModule = {
    namespaced: true,
    state: {
        dossier_id: null,
        detail: {
            address: {},
            log: {
                actions: []
            },
            measures: [],
            attention: [],
            attachments: [],
            internal_documents: [],
            contact: {
                customer: {
                    name: '',
                    email: '',
                    phone: ''
                },
                owner: {
                    name: '',
                    email: '',
                    phone: ''
                }
            },
            examination: {},
            new_examinations: [],
            old_examinations: []
        },
        logEntry: {
            commenting_participants: []
        },
        displayLogEntry: false,
        displayGenerateTemplate: false,
        displayAddAction: false,

    },
    getters: {
        isAOldDossier: state => {
            if ((state.detail.examination.result === 'NC' || state.detail.examination.result === 'NCO') && dayjs(state.detail.examination.date).isBefore('2020-03-21')){
                return true
            }
            else
            {
                return false
            }
        },
        getDossierId: state => state.dossier_id,
        getCurrentDossier: state => {
            if (!state.detail.examination) return ''
            return state.detail.examination.dossier_number
        },
        getDossierAddress: state => {
            if (!state.detail.address) return '';
            return `${state.detail.address.street_name} ${state.detail.address.street_number}${state.detail.address.street_number_addition}, ${state.detail.address.city_postcode} ${state.detail.address.city_name}`
        },
        getDossierMeasures: state => {
            if (!state.detail.measures) return [];
            return state.detail.measures
        },
        getDossierAttention: state => {
            if (!state.detail.attention) return [];
            return state.detail.attention
        },
        getDossierLogbookItems: state => {
            if (!state.detail.log) return []
            return _.chain(state.detail.log.actions)
                .orderBy(['executed_at'], ['desc'])
                .value()
        },
        getDossierNewExaminations: state => {
            return state.detail.new_examinations
        },
        getDossierPreviousExaminations: state => {
            return state.detail.old_examinations
        },
        getDossierPreviousExaminationsKPR: (state, getters) => {
            if (getters.getDossierPreviousExaminations.length === 0) return null
            return getters.getDossierPreviousExaminations.map((examination) => examination.code).join(', ')

        },
        getDossierAttachments: state => {
            if (!state.detail.attachments) return []
            return state.detail.attachments
        },
        getDossierInternalDocuments: state => {
            if (!state.detail.internal_documents) return []
            return state.detail.internal_documents
        },
        getDossierCustomer: state => state.detail.contact.customer,
        getDossierOwner: state => state.detail.contact.owner,
        hasNewExaminations: state => state.detail.new_examinations.length > 0,
        isArchived: state => state.detail.archived
    },
    mutations: {
        SET_DOSSIER_ID: (state, payload) => state.dossier_id = payload,
        SET_DETAIL: (state, payload) => state.detail = payload,
        SET_LOG_ENTRY: (state, payload) => state.logEntry = payload,
        SET_DISPLAY_LOG_ENTRY: (state, payload) => state.displayLogEntry = payload,
        SET_DISPLAY_GENERATE_TEMPLATE: (state, payload) => state.displayGenerateTemplate = payload,
        SET_DISPLAY_ADD_ACTION: (state, payload) => state.displayAddAction = payload,
        ADD_REMARK_TO_ACTION: (state, payload) => state.logEntry.commenting_participants.push(payload),
        UPDATE_ACTION_ATTACHMENTS: (state, payload) => state.logEntry.attachments.splice(0, state.logEntry.attachments.length, ...payload),
        UPDATE_DOSSIER_ATTACHMENTS: (state, payload) => state.detail.attachments.splice(0, state.detail.attachments.length, ...payload),
        ADD_TO_DOSSIER_ATTACHMENTS: (state, payload) => state.detail.attachments.push(...payload),
        UPDATE_LOG_ENTRY_DEADLINE: (state, payload) => {
            state.logEntry.deadline = payload.deadline
            state.logEntry.deadline_formatted = payload.deadline_formatted
        },
        UPDATE_ENTRY_DEADLINE: (state, payload) => {
            state.detail.log.actions[payload.index].deadline = payload.deadline
            state.detail.log.actions[payload.index].deadline_formatted = payload.deadline_formatted
        },
        DELETE_REMARK_FROM_ACTION: (state, index) => state.logEntry.commenting_participants.splice(index, 1)
    },
    actions: {
        setDetail: ({commit}, payload) => commit('SET_DETAIL', payload),
        setLogEntry: ({commit, dispatch}, payload) => {

            ApiService.getActionDetail(payload.id)
                .then(response => {
                    let data = {
                        ...payload,
                        ...response.data
                    }
                    commit('SET_LOG_ENTRY', data)

                    dispatch('setDisplayLogEntry', true)
                })
        },
        setDisplayLogEntry: ({dispatch, commit}, payload) => {
            dispatch('toggleModal', payload, {root: true})
            commit('SET_DISPLAY_LOG_ENTRY', payload)
        },
        setDisplayGenerateTemplate: ({dispatch, commit}, payload) => {
            dispatch('toggleModal', payload, {root: true})
            commit('SET_DISPLAY_GENERATE_TEMPLATE', payload)
        },
        setDisplayAddAction: ({dispatch, commit}, payload) => {
            dispatch('toggleModal', payload, {root: true})
            commit('SET_DISPLAY_ADD_ACTION', payload)
        },
        setDosssierId: ({commit}, payload) => commit('SET_DOSSIER_ID', payload),
        fetchDossierData: ({state, dispatch, rootGetters}) => {
            const dossier_id = state.dossier_id;

            const dossierData = rootGetters['DossierModule/getDossierById'](dossier_id)

            ApiService
                .getDossierDetails(dossier_id)
                .then((response) => {

                    let responseData = new DossierDetail(response.data)

                    const data = {...state.detail, ...dossierData, ...responseData}

                    dispatch('setDetail', data)
                        .then(() => {
                            dispatch('setLoaded', true, {root: true})
                        })


                })
                .catch(() => {

                    // router.push({name: 'index'})
                })
        },
        addRemarkToAction: ({commit}, payload) => commit('ADD_REMARK_TO_ACTION', payload),
        deleteRemarkFromAction: ({state, commit}, payload) => {
            const index = state.logEntry.commenting_participants.findIndex(participants => participants.id === payload.id)
            if (index !== -1) commit('DELETE_REMARK_FROM_ACTION', index)
        },
        updateActionAttachments: ({state, commit}, payload) => {
            const UniqueAttachments = _.xorBy(state.detail.attachments, payload, 'id')

            commit('ADD_TO_DOSSIER_ATTACHMENTS', UniqueAttachments)
            commit('UPDATE_ACTION_ATTACHMENTS', payload)
        },
        updateDossierAttachments: ({commit}, payload) => commit('UPDATE_DOSSIER_ATTACHMENTS', payload),
        updateLogActionDeadline: ({commit}, payload) => {
            commit('UPDATE_LOG_ENTRY_DEADLINE', {
                deadline: payload,
                deadline_formatted: DateHelper.formatDateToString(payload)
            })
        },
        updateActionDeadline: ({state, commit}, payload) => {
            const index = state.detail.log.actions.findIndex(action => action.id === payload.id)

            if (index !== -1) {
                commit('UPDATE_ENTRY_DEADLINE', {
                    index: index,
                    deadline: payload.deadline,
                    deadline_formatted: DateHelper.formatDateToString(payload.deadline)
                })
            }
        },
    },
    modules: {
        DossierDetailInspectionModule
    }
}

