const ORDER_BY_MODEL = {
    DOSSIER_NUMBER: 'examination.dossier_number',
    TYPE: 'examination.type',
    RESULT: 'examination.result',
    EXAMINATION: 'examination.date',
    TERM: 'examination.term',
    NEW_EXAMINATION: 'examination.review',
    ADDRESS: 'address.street_name',
    ADDRESS_NUMBER: 'address.street_number',
    LAST_ACTION: 'last_action.action',
    DATE_ACTION: 'last_action.executed_at',
    ACTION_DEADLINE: 'last_action.deadline'
}

import {FILTERSTATE} from "@/services/data/constants";

export const OrderByModule = {
    namespaced: true,
    state: {
        order_by: {
            dossier_number: FILTERSTATE.DISABLED,
            type: FILTERSTATE.DISABLED,
            result: FILTERSTATE.DISABLED,
            examination: FILTERSTATE.ENABLED_DESC,
            term: FILTERSTATE.DISABLED,
            new_examination: FILTERSTATE.DISABLED,
            address: FILTERSTATE.DISABLED,
            last_action: FILTERSTATE.DISABLED,
            date_action: FILTERSTATE.DISABLED,
            action_deadline: FILTERSTATE.DISABLED,
        }
    },
    getters: {
        getState: (state) => (index) => state.order_by[index],
        sortData: (state) => {
            const data = {
                sort: [],
                order: []
            }

            if (state.order_by.dossier_number !== FILTERSTATE.DISABLED) {
                data.sort.push(ORDER_BY_MODEL.DOSSIER_NUMBER)
                state.order_by.dossier_number === FILTERSTATE.ENABLED_ASC ? data.order.push('asc') : data.order.push('desc')
            }

            if (state.order_by.type !== FILTERSTATE.DISABLED) {
                data.sort.push(ORDER_BY_MODEL.TYPE)
                state.order_by.type === FILTERSTATE.ENABLED_ASC ? data.order.push('asc') : data.order.push('desc')
            }

            if (state.order_by.result !== FILTERSTATE.DISABLED) {
                data.sort.push(ORDER_BY_MODEL.RESULT)
                state.order_by.result === FILTERSTATE.ENABLED_ASC ? data.order.push('asc') : data.order.push('desc')
            }

            if (state.order_by.examination !== FILTERSTATE.DISABLED) {
                data.sort.push(ORDER_BY_MODEL.EXAMINATION)
                state.order_by.examination === FILTERSTATE.ENABLED_ASC ? data.order.push('asc') : data.order.push('desc')
            }

            if (state.order_by.new_examination !== FILTERSTATE.DISABLED) {
                data.sort.push(ORDER_BY_MODEL.NEW_EXAMINATION)
                state.order_by.new_examination === FILTERSTATE.ENABLED_ASC ? data.order.push('asc') : data.order.push('desc')
            }

            if (state.order_by.term !== FILTERSTATE.DISABLED) {
                data.sort.push(ORDER_BY_MODEL.TERM)
                state.order_by.term === FILTERSTATE.ENABLED_ASC ? data.order.push('asc') : data.order.push('desc')
            }

            if (state.order_by.address !== FILTERSTATE.DISABLED) {
                data.sort.push(ORDER_BY_MODEL.ADDRESS)
                data.sort.push(ORDER_BY_MODEL.ADDRESS_NUMBER)
                state.order_by.address === FILTERSTATE.ENABLED_ASC ? data.order.push('asc') : data.order.push('desc')
                data.order.push('asc')
            }

            if (state.order_by.last_action !== FILTERSTATE.DISABLED) {
                data.sort.push(ORDER_BY_MODEL.LAST_ACTION)
                state.order_by.last_action === FILTERSTATE.ENABLED_ASC ? data.order.push('asc') : data.order.push('desc')
            }

            if (state.order_by.date_action !== FILTERSTATE.DISABLED) {
                data.sort.push(ORDER_BY_MODEL.DATE_ACTION)
                state.order_by.date_action === FILTERSTATE.ENABLED_ASC ? data.order.push('asc') : data.order.push('desc')
            }

            if (state.order_by.action_deadline !== FILTERSTATE.DISABLED) {
                data.sort.push(ORDER_BY_MODEL.ACTION_DEADLINE)
                state.order_by.action_deadline === FILTERSTATE.ENABLED_ASC ? data.order.push('asc') : data.order.push('desc')
            }

            return data
        },
        getSort: (state, getters) => getters.sortData.sort,
        getOrders: (state, getters) => getters.sortData.order,
    },

    mutations: {
        SET_ORDER_BY: (state, payload) => state.order_by[payload.label] = payload.state
    },
    actions: {
        setOrderBy: ({state, commit}, payload) => {
            let data = {}
            if (state.order_by[payload] === FILTERSTATE.DISABLED) {
                data = {
                    label: payload,
                    state: FILTERSTATE.ENABLED_ASC
                }
            }

            if (state.order_by[payload] === FILTERSTATE.ENABLED_ASC) {
                data = {
                    label: payload,
                    state: FILTERSTATE.ENABLED_DESC
                }
            }

            if (state.order_by[payload] === FILTERSTATE.ENABLED_DESC) {
                data = {
                    label: payload,
                    state: FILTERSTATE.DISABLED
                }
            }

            commit('SET_ORDER_BY', data)
        }
    },
}
