import {LastAction} from "@/services/models/LastAction";
import {DateHelper} from "@/mixins/formatDate";
import Address from "@/services/models/Address";

export default class Dossier {
    id = ''
    examination = {
        dossier_number: '',
        type: '',
        result: '',
        date: '',
        date_formatted: '',
        term: '',
        term_formatted: '',
        review: ''
    }

    building_permit = false
    disconnection_project_building = false

    address = {}
    measures = []
    measure_chapters = []
    actions = []

    last_action = {}

    created_at = null

    constructor(dossier) {
        this.id = dossier.id
        this.setAddress(dossier)
        this.setExamination(dossier)
        this.setLastAction(dossier)


        if (dossier.created_at) this.created_at = dossier.created_at
        if (dossier.measures) this.measures = dossier.measures
        if (dossier.measure_chapters) this.measure_chapters = dossier.measure_chapters
    }

    setAddress(dossier) {
        this.address = new Address()
        if (dossier.address) this.address.setAddress(dossier.address)
    }

    setExamination(dossier) {
        this.examination.dossier_number = dossier.examination.dossier_number
        this.examination.type = dossier.examination.type
        this.examination.result = dossier.examination.result
        this.examination.date = dossier.examination.date ?? null
        this.examination.date_formatted = dossier.examination.date ? DateHelper.formatUnixDateToString(dossier.examination.date) : null
        this.examination.term = dossier.examination.term ?? null
        this.examination.term_formatted = dossier.examination.term ? DateHelper.formatUnixDateToString(dossier.examination.term) : null
        this.examination.review = dossier.examination.review

        this.building_permit = dossier.examination.building_permit ?? false
        this.disconnection_project_building = dossier.examination.disconnection_project_building ?? false
    }

    setLastAction(dossier) {
        this.last_action = new LastAction({
            action: dossier.last_action.type,
            executed_at: dossier.last_action.date ? DateHelper.formatUnixToDate(dossier.last_action.date) : null,
            deadline: dossier.last_action.deadline ? DateHelper.formatUnixToDate(dossier.last_action.deadline) : null,
        })
    }
}
