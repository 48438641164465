<template>
  <div class="mb-8p">
    <vl-filter-header :label="label" @click="toggle" @clear-filter="$emit('clear-filter')"/>
    <div v-show="isOpen">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import VlFilterHeader from "@/components/Elements/Filters/Mobile/VlFilterHeader";

export default {
  name: "VlMobileFilterTemplate",
  components: {
    VlFilterHeader,
  },
  props: {
    label: {
      type: String,
      required: true
    },
    open: {
      type: Boolean,
      required: false,
      default: true
    },
    toggleable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isOpen: true
    }
  },
  methods: {
    toggle() {
      if (this.toggleable) {
        this.isOpen = !this.isOpen
      }
    }
  },
  created() {
    this.isOpen = this.open
  }
}
</script>

<style scoped>

</style>
