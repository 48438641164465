export const ORDER_BY_LABELS = {
    DOSSIER_NUMBER: 'dossier_number',
    TYPE: 'type',
    RESULT: 'result',
    EXAMINATION: 'examination',
    TERM: 'term',
    NEW_EXAMINATION: 'new_examination',
    ADDRESS: 'address',
    LAST_ACTION: 'last_action',
    DATE_ACTION: 'date_action',
    ACTION_DEADLINE: 'action_deadline'
}



export const FILTERSTATE = {
    DISABLED: 0,
    ENABLED_ASC: 1,
    ENABLED_DESC: 2
}


