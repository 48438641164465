<template>
  <div>
    <div><span class="text-12p text-gray-light 2xl:text-14p select-none">{{ label }}</span></div>
    <div><span class="text-14p 2xl:text-16p select-none" v-html="value"></span></div>
  </div>
</template>

<script>
export default {
  name: "VlModalDisplayData",
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    }
  }
}
</script>

<style scoped>

</style>
