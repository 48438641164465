<template>
  <div>
    <date-picker :format="format" placeholder="dd-mm-jjjj" :type="type" prefix-class="xmx-large" v-model="Model" :clearable="clearable"/>
  </div>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/nl-be';

export default {
  name: "VlDatePicker",
  components: {
    DatePicker
  },
  props: {
    value: {
      type: [Date, null]
    },
    type: {
      type: String,
      required: false,
      default: 'date'
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    format: {
      type: String,
      required: false,
      default: "DD-MM-YYYY"
    }
  },
  computed: {
    Model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss">
$namespace: 'xmx-large';

$default-color: #555;
$primary-color: rgba(0, 121, 194, 1);

@import '~vue2-datepicker/scss/index.scss';

.#{$namespace}-datepicker {
  width: 100%;
}

.#{$namespace}-input {
  @apply border;
  @apply border-solid;
  @apply border-gray-border;
  @apply rounded-4p;
  @apply bg-gray-border-30;
  @apply shadow-none;

}
</style>
