export const ACTIONS = [
    {
        id: 1,
        value: 1,
        label: 'Herinneringsbrief 1'
    },
    {
        id: 2,
        value: 2,
        label: 'Herinneringsbrief 2'
    },
    {
        id: 3,
        value: 3,
        label: 'Herinneringsbrief 3'
    },
    {
        id: 4,
        value: 4,
        label: 'Aanmaning 1 (Indien geen keuring)'
    },
    {
        id: 5,
        value: 5,
        label: 'Aanmaning 1 (Indien keuring niet conform)'
    },
    {
        id: 6,
        value: 6,
        label: 'Aanmaning 2 (Indien geen keuring)'
    },
    {
        id: 7,
        value: 7,
        label: 'Aanmaning 2 (Indien keuring niet conform)'
    },
    {
        id: 8,
        value: 8,
        label: 'PV opgemaakt'
    },
    {
        id: 9,
        value: 9,
        label: 'Belastingreglement'
    },
    {
        id: 10,
        value: 10,
        label: 'Waarborg ontvangen'
    },
    {
        id: 11,
        value: 11,
        label: 'Waarborg terug gegeven'
    },


]
