<template>
<div>
  <vl-mobile-filter-template label="Dossier(s) met nieuwe keuring"
                             :open="open"
                             :toggleable="toggleable"
                             @clear-filter="clearFilters">

    <vl-checkbox label="Toon enkel nieuwe keuringen" model-value="false" v-model="OnlyNewExaminations"/>
    <vl-checkbox label="Toon enkel zonder nieuwe keuringen" model-value="false" v-model="OnlyWithoutExaminations"/>
  </vl-mobile-filter-template>

</div>
</template>

<script>
import VlMobileFilterTemplate from "@/components/Elements/Filters/VlFilterTemplate";
import VlCheckbox from "@/components/Elements/Form/Elements/VlCheckbox";
import {FILTERS} from "@/store/_dossiers_filters.module";

export default {
name: "VlNewExaminationFilter",
  components: {
    VlMobileFilterTemplate,
    VlCheckbox
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: true
    },
    toggleable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    OnlyNewExaminations: {
      get() {
        return this.$store.state.DossierModule.FilterModule.filters.only_with_new_examination
      },
      set(value) {
        this.$store.dispatch('DossierModule/FilterModule/updateFilter', {
          filter: FILTERS.ONLY_WITH_NEW_EXAMINATION,
          data: value
        })
      }
    },
    OnlyWithoutExaminations:{
      get() {
        return this.$store.state.DossierModule.FilterModule.filters.only_without_new_examination
      },
      set(value) {
        this.$store.dispatch('DossierModule/FilterModule/updateFilter', {
          filter: FILTERS.ONLY_WITHOUT_NEW_EXAMINATION,
          data: value
        })
      }
    }
  },
  methods:{
  clearFilters() {
    this.$store.dispatch('DossierModule/FilterModule/clearOnlyWithNewExaminationFilter')
    this.$store.dispatch('DossierModule/FilterModule/clearOnlyWithoutNewExaminationFilter')
  }
  }
}
</script>

<style scoped>

</style>
