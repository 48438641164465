<template>
  <div class="bg-white shadow-header">
    <div class="container grid grid-cols-1fr-min">
      <div class="py-10p">
        <img :src="require('@/assets/images/vlario_logo.png')" alt="" class="h-40p">
      </div>

      <div class="grid grid-cols-2fr-1fr-1fr h-full">
        <div class="hidden lg:block place-self-center pr-30p flex">
          <div class="flex">
            <input type="text" placeholder="Zoek een specifiek dossier..." v-model="Model"> <img
              :src="require('@/assets/images/search.svg')" class="w-16p h-auto" alt="">
          </div>

        </div>
<!--        <div-->
<!--            class="hidden xl:flex flex-col justify-center border-l border-r border-solid border-gray-border px-30p h-full">-->
<!--          <div class="text-14p whitespace-nowrap">Jean-Paul Becu</div>-->
<!--          <div class="text-12p text-blue-dark whitespace-nowrap text-center">krp@vlario.be</div>-->
<!--        </div>-->
        <div class="place-self-center pl-20p cursor-pointer" @click="logout">
          <div class="w-20p h-20p">
            <img :src="require('@/assets/images/sign_out.svg')" alt="">
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as APIService from '@/services/ApiService'

export default {
  name: "VlNavbar",
  computed: {
    Model: {
      get() {
        return this.$store.state.DossierModule.FilterModule.filters.search
      },
      set(value) {
        this.$store.dispatch('DossierModule/FilterModule/updateFilter', {
          filter: 'search',
          data: value
        })
      }
    }
  },
  methods: {
    logout() {
      APIService.logout()
    }
  }
}
</script>

<style scoped>

</style>
