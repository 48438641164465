<template>
  <div class="px-2 grid grid-cols-index-grid 3xl:grid-cols-index-grid-3xl gap-8p bg-gray-light-20 py-8p mb-12p rounded-4p">
    <div class="self-center justify-self-center">
      <vl-index-checkbox
          id_prefix="index_label_checkbox"
          :is-checked="$store.getters['DossierModule/areAllDossiersSelected']"
          @click="$store.dispatch('DossierModule/selectAllDossiers')"
      />
    </div>
    <div class="self-center flex"
         @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.DOSSIER_NUMBER)">

      <span class="text-blue-light text-12p whitespace-nowrap select-none inline-block mr-4p">Dossiernummer</span>
      <vl-sort-icon
          :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.DOSSIER_NUMBER) === FILTERSTATE.ENABLED_ASC"
          :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.DOSSIER_NUMBER) === FILTERSTATE.ENABLED_DESC"/>
    </div>
    <div class="self-center flex"
         @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.TYPE)">
      <span class="text-blue-light text-12p whitespace-nowrap select-none inline-block mr-4p">Type</span>
      <vl-sort-icon
          :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.TYPE) === FILTERSTATE.ENABLED_ASC"
          :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.TYPE) === FILTERSTATE.ENABLED_DESC"
      />
    </div>
    <div class="self-center flex"
         @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.RESULT)">
      <span class="text-blue-light text-12p whitespace-nowrap select-none inline-block mr-4p">Resultaat</span>
      <vl-sort-icon
          :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.RESULT) === FILTERSTATE.ENABLED_ASC"
          :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.RESULT) === FILTERSTATE.ENABLED_DESC"
      />
    </div>
    <div class="self-center flex"
         @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.EXAMINATION)"><span
        class="text-blue-light text-12p whitespace-nowrap select-none inline-block mr-4p">Keuring</span>
      <vl-sort-icon
          :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.EXAMINATION) === FILTERSTATE.ENABLED_ASC"
          :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.EXAMINATION) === FILTERSTATE.ENABLED_DESC"
      />

    </div>
    <div class="self-center flex"
         @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.TERM)">
      <span class="text-blue-light text-12p whitespace-nowrap select-none inline-block mr-4p">Hersteltermijn</span>
      <vl-sort-icon
          :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.TERM) === FILTERSTATE.ENABLED_ASC"
          :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.TERM) === FILTERSTATE.ENABLED_DESC"
      />

    </div>
    <div class="self-center flex"
         @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.NEW_EXAMINATION)"><span
        class="text-blue-light text-12p whitespace-nowrap select-none inline-block mr-4p">Nieuwe keuring</span>
      <vl-sort-icon
          :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.NEW_EXAMINATION) === FILTERSTATE.ENABLED_ASC"
          :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.NEW_EXAMINATION) === FILTERSTATE.ENABLED_DESC"
      />

    </div>
    <div class="self-center flex"
         @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.ADDRESS)"><span
        class="text-blue-light text-12p whitespace-nowrap select-none inline-block mr-4p">Adres</span>
      <vl-sort-icon
          :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.ADDRESS) === FILTERSTATE.ENABLED_ASC"
          :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.ADDRESS) === FILTERSTATE.ENABLED_DESC"
      />
    </div>
    <div class="self-center flex"
         @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.LAST_ACTION)"><span
        class="text-blue-light text-12p whitespace-nowrap select-none inline-block mr-4p">Laatste actie</span>
      <vl-sort-icon
          :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.LAST_ACTION) === FILTERSTATE.ENABLED_ASC"
          :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.LAST_ACTION) === FILTERSTATE.ENABLED_DESC"
      />
    </div>
    <div class="self-center flex"
         @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.DATE_ACTION)"><span
        class="text-blue-light text-12p whitespace-nowrap select-none inline-block mr-4p">Datum actie</span>
      <vl-sort-icon
          :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.DATE_ACTION) === FILTERSTATE.ENABLED_ASC"
          :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.DATE_ACTION) === FILTERSTATE.ENABLED_DESC"
      />
    </div>
    <div class="self-center flex"
         @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.ACTION_DEADLINE)"><span
        class="text-blue-light text-12p whitespace-nowrap select-none inline-block mr-4p">Deadline</span>
      <vl-sort-icon
          :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.ACTION_DEADLINE) === FILTERSTATE.ENABLED_ASC"
          :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.ACTION_DEADLINE) === FILTERSTATE.ENABLED_DESC"
      />
    </div>
    <div class="self-center"></div>
  </div>
</template>

<script>

import {ORDER_BY_LABELS} from '@/services/data/constants'
import {FILTERSTATE} from '@/services/data/constants'
import VlSortIcon from "@/components/Elements/VlSortIcon";
import VlIndexCheckbox from "@/components/Index/VlIndexCheckbox";

export default {
  name: "VlIndexLabels",
  components: {
    VlSortIcon,
    VlIndexCheckbox
  },
  data() {
    return {
      ORDER_BY_LABELS,
      FILTERSTATE
    }
  }
}
</script>

<style scoped>

</style>
