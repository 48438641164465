<template>
  <div>
    <div class="">
      <div class="">
        <vl-label label="Template"/>
      </div>

      <div class="grid grid-cols-1 lg:grid-cols-2 lg:mb-16p">
        <div>
          <vl-select :values="TemplatesTitles" v-model="selected_template" />
        </div>

        <div class="flex justify-center lg:justify-end h-36p my-16p lg:my-0">
          <p class="text-blue-light cursor-pointer absolute" @click="copyText" id="copytext">
            <font-awesome-icon icon="copy" class="mr-2"/>
            Kopieer naar klembord
          </p>
          <p class="text-blue-light cursor-pointer absolute opacity-0" @click="copyText" id="copiedtext">
            <font-awesome-icon icon="check" class="mr-2 text-green"/>
            Gekopieerd
          </p>
        </div>
      </div>

    </div>

    <div
        class="
        w-full max-h-450p overflow-x-hidden border border-solid border-gray-border
        overflow-y-scroll
        bg-gray-border-30 row-auto rounded p-16p select-none"
        ref="templateText" v-html="SelectedTemplateFilled">
    </div>
  </div>

</template>
<script>
import VlLabel from "@/components/Elements/Form/Elements/VlLabel";
import VlSelect from "@/components/Elements/Form/Elements/VlSelect";
import {DateHelper} from "@/mixins/formatDate";
import gsap from "gsap";

const TemplateFillIns = {
  NAME: '[[name]]',
  ADDRESS: '[[address]]',
  HB_1_DEADLINE: '[[hb_1_deadline]]',
  HB_2_DEADLINE: '[[hb_2_deadline]]',
  HB_3_DEADLINE: '[[hb_3_deadline]]',
  AM_1_DEADLINE: '[[am_1_deadline]]',
  AM_2_DEADLINE: '[[am_2_deadline]]',
  EXECUTED_BY: '[[executed_by]]',
  DATE_EXAMINATION: '[[date_examination]]',
  REMEDIAL_MEASURES: '[[remedial_measures]]',
  DEADLINE_REMEDIAL_MEASURES: '[[deadline_remedial_measures]]',
  FIRST_DEADLINE: '[[first_deadline]]',
  PREVIOUS_DEADLINE: '[[previous_deadline]]',
  COUNCIL_DATE: '[[council_date]]',
  LINK_TO_INFO: '[[link_to_info]]',
  HB_1_EXECUTION_TIME: '[[hb_1_execution_time]]',
  HB_2_EXECUTION_TIME: '[[hb_2_execution_time]]',
  HB_3_EXECUTION_TIME: '[[hb_3_execution_time]]',
  AM_1_EXECUTION_TIME: '[[am_1_execution_time]]',
  AM_2_EXECUTION_TIME: '[[am_2_execution_time]]',
}

const ACTIONMAPPING = {
  HERINNERINGSBRIEF_1: 'Herinneringsbrief 1 verstuurd',
  HERINNERINGSBRIEF_2: 'Herinneringsbrief 2 verstuurd',
  HERINNERINGSBRIEF_3: 'Herinneringsbrief 3 verstuurd',
  AANMANING_1: 'Aanmaning 1 verstuurd',
  AANMANING_2: 'Aanmaning 2 verstuurd'
}

export default {
  name: "VlDisplayTemplate",
  components: {
    VlLabel,
    VlSelect
  },
  props: {
    dossier: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selected_template: 5,
      placeholderText: `<span style="color:#B2C0C9">|ZELF IN TE VULLEN|</span>`,
    }
  },
  computed: {
    Customer() {
      return this.dossier.contact.owner.name ?? this.placeholderText
    },
    Adress() {
      return this.dossier.address ? ` ${this.dossier.address.street_name} ${this.dossier.address.street_number}${this.dossier.address.street_number_addition}, ${this.dossier.address.city_postcode} ${this.dossier.address.city_name}` : this.placeholderText
    },
    HasLogbookItems() {
      return this.LogbookItems.length > 0
    },
    LogbookItems() {
      if (!this.dossier.log.actions) return []
      return this.dossier.log.actions;
    },
    HB_1_Deadline() {
      if (!this.HasLogbookItems) return this.placeholderText

      const action = this.LogbookItems.find(item => item.name === ACTIONMAPPING.HERINNERINGSBRIEF_1)
      if (!this.isValidAction(action, 'deadline')) return this.placeholderText
      return action.deadline_formatted
    },
    HB_2_Deadline() {
      if (!this.HasLogbookItems) return this.placeholderText

      const action = this.LogbookItems.find(item => item.name === ACTIONMAPPING.HERINNERINGSBRIEF_2)
      if (!this.isValidAction(action, 'deadline')) return this.placeholderText

      return action.deadline_formatted
    },
    HB_3_Deadline() {
      if (!this.HasLogbookItems) return this.placeholderText

      const action = this.LogbookItems.find(item => item.name === ACTIONMAPPING.HERINNERINGSBRIEF_3)
      if (!this.isValidAction(action, 'deadline')) return this.placeholderText

      return action.deadline_formatted
    },
    AM_1_Deadline() {
      if (!this.HasLogbookItems) return this.placeholderText

      const action = this.LogbookItems.find(item => item.name === ACTIONMAPPING.AANMANING_1)
      if (!this.isValidAction(action, 'deadline')) return this.placeholderText

      return action.deadline_formatted
    },
    AM_2_Deadline() {
      if (!this.HasLogbookItems) return this.placeholderText

      const action = this.LogbookItems.find(item => item.name === ACTIONMAPPING.AANMANING_2)
      if (!this.isValidAction(action, 'deadline')) return this.placeholderText

      return action.deadline_formatted
    },
    HB_1_Execution_time() {
      if (!this.HasLogbookItems) return this.placeholderText

      const action = this.LogbookItems.find(item => item.name === ACTIONMAPPING.HERINNERINGSBRIEF_1)
      if (!this.isValidAction(action, 'executed_at')) return this.placeholderText

      return DateHelper.formatDateToString(action.executed_at)
    },
    HB_2_Execution_time() {
      if (!this.HasLogbookItems) return this.placeholderText

      const action = this.LogbookItems.find(item => item.name === ACTIONMAPPING.HERINNERINGSBRIEF_2)
      if (!this.isValidAction(action, 'executed_at')) return this.placeholderText

      return DateHelper.formatDateToString(action.executed_at)
    },
    HB_3_Execution_time() {
      if (!this.HasLogbookItems) return this.placeholderText

      const action = this.LogbookItems.find(item => item.name === ACTIONMAPPING.HERINNERINGSBRIEF_3)
      if (!this.isValidAction(action, 'executed_at')) return this.placeholderText

      return DateHelper.formatDateToString(action.executed_at)
    },
    AM_1_Execution_time() {
      if (!this.HasLogbookItems) return this.placeholderText

      const action = this.LogbookItems.find(item => item.name === ACTIONMAPPING.AANMANING_1)
      if (!this.isValidAction(action, 'executed_at')) return this.placeholderText

      return DateHelper.formatDateToString(action.executed_at)
    },
    AM_2_Execution_time() {
      if (!this.HasLogbookItems) return this.placeholderText

      const action = this.LogbookItems.find(item => item.name === ACTIONMAPPING.AANMANING_2)
      if (!this.isValidAction(action, 'executed_at')) return this.placeholderText

      return DateHelper.formatDateToString(action.executed_at)
    },
    Measures() {
      if (this.dossier.measures.length === 0) return this.placeholderText
      const measures = this.dossier.measures.map((measure) => {
        return `<div class="ml-2">- ${measure.description}\n</div>`
      })

      return measures.join('')
    },
    DateExamination() {
      if (!this.dossier?.examination?.date) return this.placeholderText
      return DateHelper.formatUnixDateToString(this.dossier.examination.date)
    },
    DeadlineRemedialMeasures() {
      if (!this.dossier?.examination?.term) return this.placeholderText
      return DateHelper.formatUnixDateToString(this.dossier.examination.term)
    },
    TemplateMatches() {
      return [
        {
          key: TemplateFillIns.NAME,
          value: this.Customer
        },
        {
          key: TemplateFillIns.ADDRESS,
          value: this.Adress
        },

        {
          key: TemplateFillIns.HB_1_DEADLINE,
          value: this.HB_1_Deadline
        },
        {
          key: TemplateFillIns.HB_2_DEADLINE,
          value: this.HB_2_Deadline
        },
        {
          key: TemplateFillIns.HB_3_DEADLINE,
          value: this.HB_3_Deadline
        },
        {
          key: TemplateFillIns.AM_1_DEADLINE,
          value: this.AM_1_Deadline
        },
        {
          key: TemplateFillIns.AM_2_DEADLINE,
          value: this.AM_2_Deadline
        },

        {
          key: TemplateFillIns.DATE_EXAMINATION,
          value: this.DateExamination
        },
        {
          key: TemplateFillIns.REMEDIAL_MEASURES,
          value: this.Measures
        },
        {
          key: TemplateFillIns.DEADLINE_REMEDIAL_MEASURES,
          value: this.DeadlineRemedialMeasures
        },
        {
          key: TemplateFillIns.COUNCIL_DATE,
          value: this.placeholderText
        },
        {
          key: TemplateFillIns.LINK_TO_INFO,
          value: this.placeholderText
        },

        {
          key: TemplateFillIns.HB_1_EXECUTION_TIME,
          value: this.HB_1_Execution_time
        },
        {
          key: TemplateFillIns.HB_2_EXECUTION_TIME,
          value: this.HB_2_Execution_time
        },
        {
          key: TemplateFillIns.HB_3_EXECUTION_TIME,
          value: this.HB_3_Execution_time
        },
        {
          key: TemplateFillIns.AM_1_EXECUTION_TIME,
          value: this.AM_1_Execution_time
        },
        {
          key: TemplateFillIns.AM_2_EXECUTION_TIME,
          value: this.AM_2_Execution_time
        }
      ]
    },
    SelectedTemplate() {
      if (!this.selected_template) return ''
      return this.Templates.find(template => template.id === this.selected_template).content
    },
    SelectedTemplateFilled() {
      let selected_template = this.SelectedTemplate;
      this.TemplateMatches.forEach((filler) => {
        selected_template = selected_template.replaceAll(filler.key, filler.value)
      })

      selected_template = selected_template.replaceAll('<br>', '<br>\n')
      return selected_template
    },
    Templates() {
      return this.$store.state.DataModule.templates
    },
    TemplatesTitles() {
      let templates = this.Templates
      return templates.sort((a, b) => a.order > b.order).map(template => {
        return {
          id: template.id,
          label: template.name
        }
      })
    }
  },
  methods: {
    isValidAction(action, param) {
      if (!action) return false
      if (!action[param]) return false
      if (action[param].length === 0) return false

      return true
    },
    playCopied() {
      let timeline = gsap.timeline()
      timeline.fromTo('#copytext', {opacity: 1}, {opacity: 0, duration: 0.3})
      timeline.fromTo('#copiedtext', {opacity: 0}, {opacity: 1, duration: 0.3})
      timeline.to('#copiedtext', {opacity: 0, duration: 0.3, delay: 0.5})
      timeline.to('#copytext', {opacity: 1, duration: 0.3})

      timeline.startTime()
    },
    copyText() {
      let textToCopy = this.$refs.templateText.textContent

     console.log(textToCopy)
      textToCopy = textToCopy.replaceAll('<br>', '\n')

      console.log(textToCopy)
      navigator.clipboard.writeText(textToCopy);
      this.playCopied()
    }
  }
}
</script>

<style scoped>

</style>
