import dayjs from "dayjs";

export class DateHelper {
    static dayjsDate

    static formatUnixToDate(unix_date) {
        DateHelper.unixToDayJS(unix_date)
        return DateHelper.dayjsDate.toDate()
    }

    static formatDateToUnix(date) {
        return dayjs(date).unix()
    }

    static formatDateToString(date, format = 'DD/MM/YYYY') {
        DateHelper.dayjsDate = dayjs(date)
        return DateHelper.formatted(format)
    }

    static formatUnixDateToString(unix_date, format = 'DD/MM/YYYY') {
        DateHelper.unixToDayJS(unix_date)
        return DateHelper.formatted(format)
    }

    static unixToDayJS(unix_date) {
        DateHelper.dayjsDate = dayjs.unix(unix_date)
    }

    static formatted(format) {
        return DateHelper.dayjsDate.format(format)
    }
}
