<template>
  <div class="flex" v-show="HasFilters">
    <div class="mr-16p mt-2p">
      <span class="text-blue-dark text-12p 2xl:text-14p select-none">Filters:</span>
    </div>

    <div class="flex flex-wrap">
      <vl-show-filter-item :filter="filter" :key="`filter_${index}`" v-for="(filter, index) in Filters"/>

      <div class="grid grid-cols-min-min mr-16p cursor-pointer ml-20p">
        <span class="text-blue-dark inline-block mr-8p self-center"> <font-awesome-icon icon="times"/></span>
        <span class="text-blue-dark text-12p 2xl:text-14p whitespace-nowrap self-center"
              @click="$store.dispatch('DossierModule/FilterModule/clearAllFilters')">Wis alle filters</span>
      </div>
    </div>
  </div>
</template>

<script>
import VlShowFilterItem from "@/components/Elements/Filters/VlShowFilterItem";

export default {
  name: "VlIndexShowFilters",
  components: {
    VlShowFilterItem
  },
  computed: {
    HasFilters(){
     return  this.Filters.length > 0
    },
    Filters() {
      return this.$store.getters["DossierModule/FilterModule/getSelectedFilters"]
    }
  }
}
</script>

<style scoped>

</style>
