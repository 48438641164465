<template>
  <label for="select_action_dropdown" id="select_action_dropdown_label" class="relative inline-block">
    <select id="select_action_dropdown" v-model="Model" class="text-blue-dark text-14p pr-14p outline-none">
      <option value="" disabled class="text-14p text-gray-border">Selecteer een actie</option>
      <option :key="action.id" :value="action" v-for="action in actions">{{ action.label }}</option>
    </select>
   <div class="absolute right-0 top-0 pointer-events-none">
      <font-awesome-icon icon="chevron-down" class="text-14p text-blue-dark"/>
    </div>

  </label>

</template>

<script>
export default {
  name: "VlIndexSelectAction",
  data() {
    return {
      actions: [
        {
          id: 'ACTIE_TOEVOEGEN',
          label: 'Actie toevoegen'
        },
        {
          id: 'GENERATE_TEMPLATE',
          label: 'Genereer template(s)'
        }
      ]
    }
  },
  computed:{
    Model:{
      get(){
        return this.value
      },
      set(value){
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss">
#select_action_dropdown_label{

}
#select_action_dropdown {
  appearance: none;
  background-color: transparent;
  padding: 0 25px 0 0;
  margin: 0;
}

#select_action_dropdown_label select{
  background:transparent;
   line-height: 1;
   border: 0;
   border-radius: 0;
   /*Hides the default arrows for Selects*/
  -webkit-appearance: none;
  -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
     position: relative;
  }

#select_action_dropdown_label {
    position: relative;
}


</style>
