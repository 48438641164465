<template>
  <div>
    <vl-mobile-filter-template label="Keuringsdatum(s)" :open="open" :toggleable="toggleable"
                               @clear-filter="$store.dispatch('DossierModule/FilterModule/clearExaminationDateFilter')">
      <div class="xl:hidden">
        <div class="mt-2 flex justify-between ">
          <div class="text-14p">Van</div>
          <div>
            <vl-date-picker-large v-model="FromDate"/>
          </div>
        </div>
        <div class="mt-2 flex justify-between">
          <div class="text-14p">t.e.m.</div>
          <div>
            <vl-date-picker-large v-model="ToDate"/>
          </div>
        </div>
      </div>
      <div class="hidden xl:grid grid-cols-1fr-min-1fr-min gap-8p">
        <div>Van</div>
      <vl-date-picker-small v-model="FromDate" />
        <div>t.e.m</div>
        <vl-date-picker-small v-model="ToDate" />
      </div>
    </vl-mobile-filter-template>
  </div>
</template>

<script>
import VlMobileFilterTemplate from "@/components/Elements/Filters/VlFilterTemplate";
import VlDatePickerLarge from '@/components/Elements/Form/Elements/VlDatePickerLarge'
import VlDatePickerSmall from "@/components/Elements/Form/Elements/VlDatePickerSmall";

export default {
  name: "VlExaminationDateFilter",
  components: {
    VlMobileFilterTemplate,
    VlDatePickerLarge,
    VlDatePickerSmall
  },
  props: {
    open: {
      type: Boolean,
      required: false,
      default: true
    },
    toggleable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    FromDate: {
      get() {
        return this.$store.state.DossierModule.FilterModule.filters.examination_date.from
      },
      set(value) {
        this.$store.dispatch('DossierModule/FilterModule/updateFilter', {
          filter: 'examination_date.from',
          data: value
        })
      }
    },
    ToDate: {
      get() {
        return this.$store.state.DossierModule.FilterModule.filters.examination_date.to
      },
      set(value) {
        this.$store.dispatch('DossierModule/FilterModule/updateFilter', {
          filter: 'examination_date.to',
          data: value
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
