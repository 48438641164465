import {DateHelper} from "@/mixins/formatDate";

export class DossierInspection {
    id = ''
    attachments = ''
    attention = ''
    code = ''
    contact = {}
    date = ''
    date_formatted = ''
    measure_deadline = ''
    measure_deadline_formatted = ''
    measures = []
    result = ''
    type= ''


    constructor(data) {
        this. id = data.id
        this.attachments = data.attachments
        this.attention = data.attention
        this.code = data.code
        this.contact = data.contact
        this.measures = data.measures
        this.result = data.result
        this.type = data.type


        this.setDate(data)
        this.setMeasureDeadline(data)
    }

    setDate(data){
        if (data.date){
            this.date = data.date
            this.date_formatted = DateHelper.formatUnixDateToString(this.date)
        }
    }

    setMeasureDeadline(data){
     if (data.measure_deadline){
         this.measure_deadline = data.measure_deadline
         this.measure_deadline_formatted = DateHelper.formatUnixDateToString(this.measure_deadline)
     }
    }
}
