<template>
  <div id="mobile-filter-menu"
       class="">
    <header class="flex justify-between mb-20p">
      <div>
        <span
            class="text-18p text-gray-light inline-block mr-8p cursor-pointer border-b border-solid border-transparent select-none"
            :class="{'text-blue-dark border-blue-dark': display_menu === SUBMENU.FILTER}"
            @click="display_menu = SUBMENU.FILTER">Filteren</span>
        <span
            class="text-18p text-gray-light inline-block mr-8p cursor-pointer border-b border-solid border-transparent select-none"
            :class="{'text-blue-dark border-blue-dark': display_menu === SUBMENU.SORT}"
            @click="display_menu = SUBMENU.SORT">Sorteren</span>
      </div>
      <div class="cursor-pointer" @click="$store.dispatch('toggleMobileFilterMenu')">
        <span class="inline-block mr-1 text-blue-dark"><font-awesome-icon icon="times"/></span> <span
          class="text-blue-dark">Sluiten</span>
      </div>
    </header>

    <section class="overflow-y-auto space-y-20p pb-40p" :key="`submenu-filter`" v-show="display_menu === SUBMENU.FILTER">
      <type-of-examination :open="true" :toggleable="false"/>
      <result-of-examination :open="true" :toggleable="false"/>
      <examination-date :open="true" :toggleable="false"/>
      <show-new-examination-filter :open="true" :toggleable="false"/>
      <last-action :open="true" :toggleable="false"/>
      <deadline :open="true" :toggleable="false"/>
      <new-examination :open="true" :toggleable="false"/>
      <city-filter :open="true" :toggleable="false"/>
      <address-filter :open="true" :toggleable="false"/>
      <measures :open="true" :toggleable="false"/>
    </section>

    <section class="overflow-y-auto" :key="`submenu-sort`" v-show="display_menu === SUBMENU.SORT">
      <div class="border-b border-solid border-gray-border-30 py-8p flex justify-between"
           @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.DOSSIER_NUMBER)">
        <div class="select-none">
          Dossiernummer
        </div>
        <div>
          <vl-sort-icon
              :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.DOSSIER_NUMBER) === FILTERSTATE.ENABLED_ASC"
              :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.DOSSIER_NUMBER) === FILTERSTATE.ENABLED_DESC"
          />
        </div>
      </div>

      <div class="border-b border-solid border-gray-border-30 py-8p flex justify-between"
           @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.TYPE)">
        <div class="select-none">
          Type
        </div>
        <div>
          <vl-sort-icon
              :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.TYPE) === FILTERSTATE.ENABLED_ASC"
              :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.TYPE) === FILTERSTATE.ENABLED_DESC"
          />
        </div>
      </div>

      <div class="border-b border-solid border-gray-border-30 py-8p flex justify-between"
           @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.RESULT)">
        <div class="select-none">
          Resultaat
        </div>
        <div>
          <vl-sort-icon
              :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.RESULT) === FILTERSTATE.ENABLED_ASC"
              :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.RESULT) === FILTERSTATE.ENABLED_DESC"
          />
        </div>
      </div>

      <div class="border-b border-solid border-gray-border-30 py-8p flex justify-between"
           @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.EXAMINATION)">
        <div class="select-none">
          Keuring
        </div>
        <div>
          <vl-sort-icon
              :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.EXAMINATION) === FILTERSTATE.ENABLED_ASC"
              :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.EXAMINATION) === FILTERSTATE.ENABLED_DESC"
          />
        </div>
      </div>

      <div class="border-b border-solid border-gray-border-30 py-8p flex justify-between"
           @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.TERM)">
        <div class="select-none">
          Hersteltermijn
        </div>
        <div>
          <vl-sort-icon
              :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.TERM) === FILTERSTATE.ENABLED_ASC"
              :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.TERM) === FILTERSTATE.ENABLED_DESC"
          />
        </div>
      </div>

      <div class="border-b border-solid border-gray-border-30 py-8p flex justify-between"
           @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.NEW_EXAMINATION)">
        <div class="select-none">
          Nieuwe keuring
        </div>
        <div>
          <vl-sort-icon
              :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.NEW_EXAMINATION) === FILTERSTATE.ENABLED_ASC"
              :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.NEW_EXAMINATION) === FILTERSTATE.ENABLED_DESC"
          />
        </div>
      </div>

      <div class="border-b border-solid border-gray-border-30 py-8p flex justify-between"
           @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.ADDRESS)">
        <div class="select-none">
          Adres
        </div>
        <div>
          <vl-sort-icon
              :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.ADDRESS) === FILTERSTATE.ENABLED_ASC"
              :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.ADDRESS) === FILTERSTATE.ENABLED_DESC"
          />
        </div>
      </div>

      <div class="border-b border-solid border-gray-border-30 py-8p flex justify-between"
           @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.LAST_ACTION)">
        <div class="select-none">
          Laatste actie
        </div>
        <div>
          <vl-sort-icon
              :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.LAST_ACTION) === FILTERSTATE.ENABLED_ASC"
              :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.LAST_ACTION) === FILTERSTATE.ENABLED_DESC"
          />
        </div>
      </div>

      <div class="border-b border-solid border-gray-border-30 py-8p flex justify-between"
           @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.DATE_ACTION)">
        <div class="select-none">
          Datum actie
        </div>
        <div>
          <vl-sort-icon
              :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.DATE_ACTION) === FILTERSTATE.ENABLED_ASC"
              :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.DATE_ACTION) === FILTERSTATE.ENABLED_DESC"
          />
        </div>
      </div>

      <div class="py-8p flex justify-between"
           @click="$store.dispatch('DossierModule/OrderByModule/setOrderBy', ORDER_BY_LABELS.ACTION_DEADLINE)">
        <div class="select-none">
          Deadline
        </div>
        <div>
          <vl-sort-icon
              :asc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.ACTION_DEADLINE) === FILTERSTATE.ENABLED_ASC"
              :desc="$store.getters['DossierModule/OrderByModule/getState'](ORDER_BY_LABELS.ACTION_DEADLINE) === FILTERSTATE.ENABLED_DESC"
          />
        </div>
      </div>
    </section>


    <section class="border-t border-solid border-gray-border ">
      <div class="">
        <vl-button :label="`Toon ${$store.getters['DossierModule/getTotalFilteredDossiers']} resultaten`"
                   classes="bg-blue-dark py-6p w-full" @click="fetchResults"></vl-button>
      </div>
    </section>
  </div>
</template>

<script>
import VlButton from "@/components/Elements/VlButton";
import VlMobileDeadlineFilter from "@/components/Elements/Filters/VlDeadlineFilter";
import VlMobileExaminationDateFilter from "@/components/Elements/Filters/VlExaminationDateFilter";
import * as FILTERS from '@/components/Elements/Filters/Filters'
import VlSortIcon from "@/components/Elements/VlSortIcon";
import {ORDER_BY_LABELS} from '@/services/data/constants'
import {FILTERSTATE} from '@/services/data/constants'

const SUBMENU = {
  FILTER: 0,
  SORT: 1
}

export default {
  name: "VlIndexFiltersMobile",
  components: {
    VlButton,
    'type-of-examination': FILTERS.VlTypeOfExaminationFilter,
    'result-of-examination': FILTERS.VlResultOfExaminationFilter,
    'last-action': FILTERS.VlLastActionFilter,
    'address-filter': FILTERS.VlAddressFilter,
    'show-new-examination-filter': FILTERS.VlNewExaminationFilter,
    'city-filter': FILTERS.VlCityFilter,
    'deadline': VlMobileDeadlineFilter,
    'measures': FILTERS.VlMeasuresFilter,
    'new-examination': FILTERS.VlNewExaminationFilter,
    'examination-date': VlMobileExaminationDateFilter,
    VlSortIcon
  },
  data() {
    return {
      display_menu: SUBMENU.FILTER,
      SUBMENU,
      ORDER_BY_LABELS,
      FILTERSTATE
    }
  },
  methods: {
    fetchResults() {
      this.$store.dispatch('toggleMobileFilterMenu')
    }
  }
}
</script>

<style lang="scss">
#mobile-filter-menu {
  @apply bg-white;
  @apply h-screen;
  @apply w-screen;
  @apply fixed;
  @apply top-0;
  @apply z-20;
  @apply p-8p;
  @apply grid;
  @apply grid-rows-mobile-filter-menu;
  @apply grid-cols-1;

  height: calc(var(--vh, 1vh) * 100);
}

</style>
