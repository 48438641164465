<template>
  <div id="modal-container">

    <div id="modal-template">
      <div class="overflow-auto max-h-screen overflow-x-hidden">
        <header class="my-30p lg:mb-30p lg:mt-0">
          <slot name="header"/>
        </header>
        <section>
          <slot/>
        </section>
        <footer class="my-30p lg:mt-30p lg:mb-0">
          <slot name="footer"/>
        </footer>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "VlModalTemplate"
}
</script>

<style lang="scss" scoped>
#modal-container {
  @apply grid;
  @apply grid-cols-1;
  @apply grid-rows-1;
  @apply h-screen;
  @apply w-screen;
  @apply fixed;
  @apply top-0;
  @apply left-0;
  @apply z-20;
}

#modal-template {
  @apply bg-white;
  @apply h-screen;
  @apply w-screen;
  @apply p-20p;
  @apply place-self-center;
  @apply grid;
  @apply grid-cols-1;
  @apply grid-rows-1;
  @apply items-center;

  @screen xl {
    @apply h-auto;

    @apply w-1028p;
    @apply rounded-4p;
    @apply max-w-1028p;
    @apply shadow-card;
  }
}
</style>
