<template>
  <header class="flex justify-between mb-20p">
    <div>
      <span class="text-18p text-blue-dark">Filteren</span>
    </div>
    <div class="cursor-pointer" @click="$emit('click')">
      <span class="inline-block mr-1 text-blue-dark"><font-awesome-icon icon="times"/></span> <span class="text-blue-dark">Sluiten</span>
    </div>
  </header>
</template>

<script>
export default {
name: "VlFilterElementHeader"
}
</script>

<style scoped>

</style>
