import * as APIService from "@/services/ApiService";

export const DataModule = {
    namespaced: true,
    state: {
        actions: [],
        types: [],
        results: [],
        measures: [],
        templates: [],

    },
    getters: {
        getActionById: state => id => state.actions.find(action => action.id === id),
        getActions: state => state.actions.filter(item => item.code !== 'Z99'),
        getDearchiveActionID: state => state.actions.find(item => item.code === 'Z99').id,
        getTypes: state => state.types,
        getTypeById: state => id => state.types.find(type => type.id === id).code,
        getResults: state => state.results,
        getMeasures: state => state.measures,
        getMeasureById: state => id => state.measures.find(measure => measure.id === id),
    },
    mutations: {
        SET_ACTIONS: (state, payload) => state.actions = payload,
        SET_TYPES: (state, payload) => state.types = payload,
        SET_RESULTS: (state, payload) => state.results = payload,
        SET_MEASURES: (state, payload) => state.measures = payload,
        SET_TEMPLATES: (state, payload) => state.templates = payload
    },
    actions: {
        getGeneralInformation: ({dispatch}) => {
            APIService
                .getGeneralInformation()
                .then(response => {
                    dispatch('setActions', response.data.action_types)
                    dispatch('setType', response.data.reasons_to_inspect)
                    dispatch('setMeasures', response.data.recovery_measures_chapters)
                })
        },
        setActions: ({commit}, payload) => {
            commit('SET_ACTIONS', payload)
        },
        setType: ({commit}, payload) => {
            commit('SET_TYPES', payload)
        },
        setMeasures: ({commit}, payload) => commit("SET_MEASURES", payload),
        getActions: ({commit}) => {
            APIService
                .getActions()
                .then(response => {
                    commit('SET_ACTIONS', response.data)
                })
        },
        getTypes: ({commit}) => {
            APIService.getTypes().then(response => commit('SET_TYPES', response.data))
        },
        getResults: ({commit}) => {
            APIService.getResults().then(response => commit('SET_RESULTS', response.data))
        },
        getTemplates: ({dispatch}) => APIService.getTemplates().then((response) => dispatch('setTemplates', response.data)),
        setTemplates: ({commit}, payload) => commit('SET_TEMPLATES', payload)
    }
}
