import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {nonEmpty} from "@/mixins/filters/NonEmpty";
import './assets/style/style.scss'
import './icons'

import VueToastr from "vue-toastr";
Vue.use(VueToastr);

Vue.config.productionTip = false
Vue.filter('nonEmpty', nonEmpty)

store.dispatch('load')

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
