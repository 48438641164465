<template>
  <div class="bg-gray-light-20 grid grid-cols-2 rounded-4p shadow-card w-full">
    <button class=" rounded-4p px-20p py-4p grid cursor-pointer transition duration-200" :class="ActiveDossiersClasses" @click="$store.dispatch('DossierModule/setShowCurrentDossiers', true); $store.dispatch('DossierModule/clearSelectedDossiers')"><span
        class="whitespace-nowrap  place-self-center">Actieve dossiers <span v-show="ShowActiveDossiers">({{$store.getters['DossierModule/getTotalFilteredDossiers']}})</span></span></button>
    <button class=" rounded-4p px-20p py-8p grid cursor-pointer text-white transition duration-200" :class="ArchivedDossierClasses" @click="$store.dispatch('DossierModule/setShowCurrentDossiers', false); $store.dispatch('DossierModule/clearSelectedDossiers')"><span
        class="whitespace-nowrap  place-self-center">Archief</span></button>
  </div>
</template>

<script>
export default {
  name: "VlArchiveButtons",
  computed: {
    ShowActiveDossiers(){
      return this.$store.state.DossierModule.show_current_dossiers
    },
    ActiveDossiersClasses(){
      if (this.ShowActiveDossiers){
        return 'bg-blue-dark text-white'
      }
        return 'bg-gray-light-20 text-blue-dark';
    },
    ArchivedDossierClasses(){
      if (!this.ShowActiveDossiers){
        return 'bg-blue-dark text-white'
      }
      return 'bg-gray-light-20 text-blue-dark';
    }
  }
}
</script>

<style scoped>

</style>
