import Vue from 'vue'

import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faUserSecret,
    faCheck,
    faChevronDown,
    faChevronUp,
    faChevronRight,
    faChevronLeft,
    faTimes,
    faPlus,
    faSort,
    faInfoCircle,
    faSortUp,
    faSortDown,
    faDownload,
    faEye,
    faTrashAlt,
    faCopy,
    faFile,
    faSpinner,
    faCircleNotch,
    faEdit,
    faArchive,
    faBars
} from '@fortawesome/free-solid-svg-icons'


library.add(
    [
        faUserSecret,
        faCheck,
        faChevronUp,
        faChevronDown,
        faChevronRight,
        faChevronLeft,
        faTimes,
        faPlus,
        faSort,
        faInfoCircle,
        faSortUp,
        faSortDown,
        faDownload,
        faEye,
        faTrashAlt,
        faCopy,
        faFile,
        faSpinner,
        faCircleNotch,
        faEdit,
        faArchive,
        faBars
    ])

Vue.component('font-awesome-icon', FontAwesomeIcon)
