<template>
  <div class="dossier-index-card" :class="{'selected' : isSelected}">
    <div class="hidden xl:block place-self-center">
      <vl-index-checkbox
          id_prefix="dossier"
          :is-checked="isSelected"
          @click="selectDossier"
      />
    </div>
    <vl-index-dossier-card-element label="Dossiernummer" :content="dossier.examination.dossier_number | nonEmpty"
                                   :flex="true"
                                   @click="toDetailLabel"/>
    <vl-index-dossier-card-element @click="toDetailLabel" label="Type" :content="dossier.examination.type"
                                   :flex="true"/>
    <vl-index-dossier-card-element @click="toDetailLabel" label="Resultaat"
                                   :content="dossier.examination.result | nonEmpty"
                                   :flex="true">
      <div class="mr-2 hidden xl:block">
        <span class="hidden xl:inline-block circle-left" :class="LeftCircleStyle"/>
        <span class="hidden xl:inline-block circle-right" :class="RightCircleStyle"/>
      </div>
    </vl-index-dossier-card-element>
    <vl-index-dossier-card-element @click="toDetailLabel" label="Keuring"
                                   :content="dossier.examination.date_formatted | nonEmpty"
                                   :flex="true"/>
    <vl-index-dossier-card-element @click="toDetailLabel" label="Hersteltermijn"
                                   :content="dossier.examination.term_formatted | nonEmpty" :flex="true"
                                   :content-class="isRecoveryDatePassed ? 'text-green-dark' : ''"
    />
    <vl-index-dossier-card-element @click="toDetailLabel" label="Nieuwe keuring"
                                   :content="dossier.examination.review ? 'Ja' : 'Nee'"
                                   :flex="true"/>
    <vl-index-dossier-card-element @click="toDetailLabel" label="Adres"
                                   :content="`${dossier.address.street_name} ${dossier.address.street_number}${this.dossier.address.street_number_addition}, ${dossier.address.city_postcode} ${dossier.address.city_name}`"
                                   :flex="$store.getters.isTablet"/>
    <vl-index-dossier-card-element @click="toDetailLabel" label="Laatste actie"
                                   :content="dossier.last_action.action | nonEmpty" :flex="$store.getters.isTablet"/>
    <vl-index-dossier-card-element @click="toDetailLabel" label="Datum actie"
                                   :content="dossier.last_action.executed_at_formatted | nonEmpty" :flex="true"/>
    <vl-index-dossier-card-element @click="toDetailLabel" label="Deadline"
                                   :content-class="isPassedDeadline ? 'text-red' : ''"
                                   :content="dossier.last_action.deadline_formatted | nonEmpty" :flex="true"/>

    <div class="hidden xl:block place-self-center" @click="toDetailLabel">
      <span class="text-blue-light">
        <font-awesome-icon icon="chevron-right"/></span>
    </div>

    <div class="grid grid-cols-2 gap-16p xl:hidden">
      <vl-button :label="isSelected ? 'Geselecteerd' : 'Selecteer'" :classes="SelectButtonClasses"
                 :icon-left="isSelected ? 'check' : '' "
                 @click="$store.dispatch('DossierModule/updateSelectedDossiers', dossier)"/>
      <vl-button label="Naar detail" classes="w-full mt-20p py-5p bg-blue-light  hover:bg-blue-light-hover"
                 icon-right="chevron-right" @click="toDetail"/>
    </div>
  </div>
</template>

<script>
import VlIndexDossierCardElement from "@/components/Elements/VlIndexDossierCardElement";
import VlIndexCheckbox from "@/components/Index/VlIndexCheckbox";
import VlButton from "@/components/Elements/VlButton";
import dayjs from "dayjs";
import IsSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(IsSameOrBefore)

export default {
  name: "VlIndexDossierCard",
  components: {
    VlIndexDossierCardElement,
    VlIndexCheckbox,
    VlButton
  },
  props: {
    dossier: {
      type: Object,
      required: true
    }
  },
  computed: {
    SelectButtonClasses() {
      if (this.isSelected) {
        return 'w-full mt-20p py-5p bg-blue-light'
      }
      return 'bg-gray-light w-full mt-20p py-5p'
    },
    isRecoveryDatePassed() {
      if (this.dossier.examination.term) {
        return dayjs(new Date()).isSameOrBefore(dayjs.unix(this.dossier.examination.term))
      } else {
        return false
      }
    },
    isPassedDeadline() {
      if (this.dossier.last_action.deadline) {
        return dayjs(this.dossier.last_action.deadline).isSameOrBefore(new Date())
      } else {
        return false
      }
    },
    Model: {
      get() {
        return this.$store.getters["DossierModule/getSelectedDossiers"]
      },
      set(value) {
        this.$store.dispatch('DossierModule/modelSelectedDossiers', value)
      }
    },
    isSelected() {
      return this.$store.getters['DossierModule/isSelected'](this.dossier.id)
    },
    LeftCircleStyle() {
      if (this.dossier.examination.result === 'NC' || this.dossier.examination.result === 'NCO') return 'bg-red'
      if (this.dossier.examination.result === 'CO' || this.dossier.examination.result === 'C') return 'bg-green'
      return ''
    },
    RightCircleStyle() {
      if (this.dossier.examination.result === 'NC') return 'bg-red'
      if (this.dossier.examination.result === 'NCO' || this.dossier.examination.result === 'CO') return 'bg-orange'
      if (this.dossier.examination.result === 'C') return 'bg-green'
      return ''
    }
  },
  methods: {
    toDetailLabel() {
      if (!this.$store.state.isMobile) {
        this.toDetail()
      }
    },
    selectDossierLabel() {
      if (!this.$store.state.isMobile) {
        this.selectDossier()
      }
    },
    selectDossier() {
      this.$store.dispatch('DossierModule/updateSelectedDossiers', this.dossier)
    },
    toDetail() {
      this.$router.push({name: 'details', params: {id: this.dossier.id}})
    }
  }
}
</script>

<style lang="scss">
.dossier-index-card {
  @apply bg-white;
  @apply shadow-card;
  @apply rounded-4p;
  @apply p-4;
  @apply mb-16p;
  @apply border;
  @apply border-solid;
  @apply border-transparent;

  @screen xl {
    @apply shadow-none;
    @apply rounded-none;
    @apply grid;
    @apply grid-cols-index-grid;
    @apply gap-8p;
    @apply p-0;
    @apply mb-0;
    @apply min-h-46p;
    @apply px-2;
    align-content: center;
    @apply transition;
    @apply duration-150;

    &:nth-child(odd) {
      @apply bg-gray-light-10;
    }

    &:hover {
      @apply bg-gray-light-30;
    }
  }

  @screen 3xl {
    @apply grid-cols-index-grid-3xl
  }
}

.dossier-index-card.selected {
  @apply border-gray-border;

  @screen xl {
    @apply border-transparent;
  }
}

$size: 7.5px;
.circle-left, .circle-right {
  @apply inline-block;
  height: $size *2;
  width: $size;
}

.circle-left {
  border-bottom-left-radius: $size * 2;
  border-top-left-radius: $size * 2;
}

.circle-right {
  border-bottom-right-radius: $size * 2;
  border-top-right-radius: $size * 2;
}
</style>

