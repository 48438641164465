<template>
  <transition
      @enter="enter"
      @leave="leave"
      :css="false">
    <slot></slot>
  </transition>
</template>

<script>
import {gsap} from 'gsap'

export default {
  name: "VlFilterTransition",
  methods: {
    enter(el, done) {
      gsap.from(el, {x: '-650px', duration: .5, clearProps: 'all', onComplete: done})
    },
    leave(el, done) {
      gsap.to(el, {x: '-650px', duration: .5, clearProps: 'all', onComplete:  done})
    }
  }
}
</script>
