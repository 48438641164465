<template>
  <div class="pb-28">
    <div class="container">
      <section class="mt-20p">
        <div class="relative">
          <div class="lg:absolute">
            <span class="text-blue-dark text-24p select-none">Mijn dossiers</span>
          </div>
          <div class="lg:flex lg:justify-center">
            <div class="lg:w-350p">
              <vl-archive-buttons/>
            </div>
          </div>
          <div class="lg:absolute right-0 top-0 py-2 lg:py-0 flex justify-end">
            <VlButton 
              classes="bg-blue-dark hover:bg-blue-dark-hover px-16p py-8p"
              label="Genereer excel"
              @click="generateExcel" />
          </div>


        </div>

        <div class="lg:hidden">
          <div class="flex my-20p w-full">
            <input type="text" placeholder="Zoek een specifiek dossier..." class="w-full px-2 py-1.5" v-model="Search">
          </div>
        </div>
      </section>

      <section>
        <div class="hidden lg:grid grid-cols-min-1fr-min gap-8p my-20p cursor-pointer">
          <div class="grid gap-8p grid-cols-min-min" @click="$store.dispatch('toggleFilterMenu')">
            <div class="w-16p h-16p self-center"><img :src="require('@/assets/images/filter.svg')" alt=""></div>
            <div class="self-center">
              <span class="text-blue-light font-600 text-14p flex select-none"> Filteren </span>
            </div>
          </div>
          <div>
            <vl-index-show-filters/>
          </div>
          <div>
            <vl-index-pagination-amount-filter/>
          </div>
        </div>

        <div class="hidden xl:block">
          <vl-index-labels/>
        </div>

        <div class="hidden lg:block">
          <vl-index-selected-dossiers @show-action-modal="toggleActionsModal"
                                      @show-templates-modal="toggleTemplatesModal"/>
        </div>

        <div class="xl:shadow-card">
          <vl-index-dossier-card :key="index" :dossier="dossier"
                                 v-for="(dossier,index) in $store.getters['DossierModule/getPaginatedDossiers']"/>


          <div class="xl:hidden"
               v-show="$store.getters['DossierModule/getTotalFilteredDossiers'] !== 0 && $store.getters['DossierModule/PaginationModule/getNumberOfPages'] > 1" >
            <div class="bg-blue-dark w-full lg:hidden rounded-4p py-8p text-center text-white" @click="$store.dispatch('DossierModule/PaginationModule/addDossiers')">Laad meer dossiers</div>
          </div>
        </div>

        <div v-if="$store.getters['DossierModule/getPaginatedDossiers'].length === 0">
          <vl-not-available-notification message="Er zijn geen dossiers gevonden"/>
        </div>


        <div class="hidden lg:grid relative grid-cols-1 my-20p">
          <div class="place-self-center">
            <vl-index-pagination/>
          </div>


          <div class="absolute right-0">
            <vl-index-pagination-amount-filter/>
          </div>
        </div>
      </section>
    </div>

    <div class="fixed w-full grid bottom-0 lg:hidden bg-blue-dark py-8p"
         style="grid-template-columns: 1fr 1px 1fr 1px 1fr">
      <div class="self-center">
        <vl-bottom-filter-button/>
      </div>
      <div class="border-r border-white border-solid h-full"></div>
      <div class="self-center">
        <VlBottomActionsButton
            @show-action-modal="toggleActionsModal"
            @show-templates-modal="toggleTemplatesModal"
        />
      </div>
      <div class="border-r border-white border-solid h-full">

      </div>
      <div class="self-center">
        <div class="text-white text-center select-none">
          <font-awesome-icon icon="archive"/>
        </div>
        <div class="text-white text-center select-none">{{
            $store.getters["DossierModule/getSelectedDossiers"].length
          }}
        </div>

      </div>

    </div>


    <vl-fade-animation>
      <div v-if="showActionsModal">
        <vl-add-actions-modal @close="toggleActionsModal"/>
      </div>

    </vl-fade-animation>


    <vl-fade-animation>
      <div v-if="showTemplatesModal">
        <vl-generate-templates-modal @close="toggleTemplatesModal"/>
      </div>
    </vl-fade-animation>

    <vl-filter-transition>
      <vl-index-filters v-if="$store.state.showFilterMenu"/>
    </vl-filter-transition>


    <vl-mobile-filter-transition>
      <vl-index-filters-mobile v-if="$store.state.showMobileFilterMenu"/>
    </vl-mobile-filter-transition>

  </div>
</template>

<script>
import VlBottomFilterButton from "@/components/Index/VlBottomFilterButton";
import VlIndexDossierCard from "@/components/Index/VlIndexDossierCard";
import VlIndexFiltersMobile from "@/components/Index/VlIndexFiltersMobile";
import VlIndexLabels from "@/components/Index/VlIndexLabels";
import VlMobileFilterTransition from "@/components/Animations/VlMobileFilterTransition";
import VlIndexFilters from "@/components/Index/VlIndexFilters";
import VlAddActionsModal from "@/components/Elements/Modal/VlAddActionsModal";
import VlFadeAnimation from "@/components/Animations/VlFadeAnimation";
import VlFilterTransition from "@/components/Animations/VlFilterTransition";
import VlIndexPaginationAmountFilter from "@/components/Index/Pagination/VlIndexPaginationAmountFilter";
import VlIndexPagination from "@/components/Index/Pagination/VlIndexPagination";
import VlIndexShowFilters from "@/components/Index/VlIndexShowFilters";
import VlIndexSelectedDossiers from "@/components/Index/VlIndexSelectedDossiers";
import {ToastMixin} from "@/mixins/ToastMixin";
import VlArchiveButtons from '@/components/Index/VlArchiveButtons'
import VlNotAvailableNotification from '@/components/Elements/VlNotAvailableNotification'
import VlGenerateTemplatesModal from '@/components/Elements/Modal/VlGenerateTemplatesModal'
import VlBottomActionsButton from '@/components/Index/VlBottomActionsButton'
import VlButton from '@/components/Elements/VlButton'
import { generateOvamExcel } from "../utils/generateExcel";
export default {
  name: "Index",
  components: {
    VlButton,
    VlIndexFilters,
    VlBottomFilterButton,
    VlIndexDossierCard,
    VlIndexFiltersMobile,
    VlIndexLabels,
    VlMobileFilterTransition,
    VlAddActionsModal,
    VlFadeAnimation,
    VlFilterTransition,
    VlIndexPaginationAmountFilter,
    VlIndexPagination,
    VlIndexShowFilters,
    VlIndexSelectedDossiers,
    VlArchiveButtons,
    VlNotAvailableNotification,
    VlGenerateTemplatesModal,
    VlBottomActionsButton

  },
  mixins: [ToastMixin],
  data() {
    return {
      showActionsModal: false,
      showTemplatesModal: false
    }
  },
  computed: {
    Search: {
      get() {
        return this.$store.state.DossierModule.FilterModule.filters.search
      },
      set(value) {
        this.$store.dispatch('DossierModule/FilterModule/updateFilter', {
          filter: 'search',
          data: value
        })
      }
    }
  },
  methods: {
    generateExcel() {
      generateOvamExcel(this.$store.getters['DossierModule/getOrderedDossiers'])
    },
    toggleActionsModal() {
      if (this.showActionsModal) {
        this.$store.dispatch('toggleModal', false)
        this.showActionsModal = false
      } else {
        this.$store.dispatch('toggleModal', true)
        this.showActionsModal = true
      }
    },
    toggleTemplatesModal() {
      if (this.showTemplatesModal) {
        this.$store.dispatch('toggleModal', false)
        this.showTemplatesModal = false
      } else {
        this.$store.dispatch('toggleModal', true)
        this.showTemplatesModal = true
      }
    }
  }

}
</script>

<style scoped>

</style>
