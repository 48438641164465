<template>
  <div class="mb-20p">
    <vl-label label="Wat is de deadline voor de opvolging?"/>
    <vl-date-picker-large v-model="Model"/>
  </div>
</template>

<script>
import VlLabel from "@/components/Elements/Form/Elements/VlLabel";
import VlDatePickerLarge from "@/components/Elements/Form/Elements/VlDatePickerLarge";

export default {
  name: "VlModalSelectDeadline",
  components: {
    VlLabel,
    VlDatePickerLarge
  },
  props: {
    value: {
      required: true
    }
  },
  computed: {
    Model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
