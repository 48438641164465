<template>
    <transition
        @enter="enter"
        @leave="leave"
        :css="false">
      <slot></slot>
    </transition>
</template>

<script>
import {gsap} from 'gsap'

export default {
  name: "VlMobileFilterTransition",
  methods: {
    enter(el, done) {
      gsap.from(el, {y: '100vh', duration: .5, clearProps: 'all', onComplete: done})
    },
    leave(el, done) {
      gsap.to(el, {y: '100vh', duration: .5, clearProps: 'all', onComplete:  done})
    }
  }
}
</script>

<style scoped>

</style>
