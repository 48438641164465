<template>
  <div class="grid" @click="$store.dispatch('toggleMobileFilterMenu')">
    <div class="place-self-center">
      <div class="flex justify-center">
           <span class="inline-block w-20p h-24p py-4p">
          <svg viewBox="0 0 14 11" version="1.1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Design-V3" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="1.1-Mijn-dossiers---Overzicht" transform="translate(-40.000000, -157.000000)" fill="#FFFFFF"
           fill-rule="nonzero">
            <g id="Group-5" transform="translate(40.000000, 153.000000)">
                <g id="np_filter_3039129_000000" transform="translate(0.000000, 4.000000)">
                    <path
                        d="M10.9998,4.03704 L3.00006,4.03704 C2.447718,4.03704 1.99983,4.483836 1.99983,5.03727 C1.99983,5.590158 2.447718,6.036954 3.00006,6.036954 L10.9998,6.036954 C11.552142,6.036954 12.00003,5.590158 12.00003,5.03727 C12.00003,4.483836 11.552142,4.03704 10.9998,4.03704 M12.9997,0.03724 L1.00016,0.03724 C0.447818,0.03724 -7.00000001e-05,0.484036 -7.00000001e-05,1.036924 C-7.00000001e-05,1.589812 0.447818,2.037154 1.00016,2.037154 L12.9997,2.037154 C13.552042,2.037154 13.99993,1.589812 13.99993,1.036924 C13.99993,0.484036 13.552042,0.03724 12.9997,0.03724 M8.9999,8.03698 L5.0001,8.03698 C4.447758,8.03698 3.99987,8.484322 3.99987,9.03721 C3.99987,9.590098 4.447758,10.036894 5.0001,10.036894 L8.9999,10.036894 C9.552242,10.036894 10.00013,9.590098 10.00013,9.03721 C10.00013,8.484322 9.552242,8.03698 8.9999,8.03698"
                        id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
        </span>
      </div>

        <span class="text-white place-self-center text-center">Filters</span>
      </div>
  </div>
</template>

<script>
export default {
  name: "VlBottomFilterButton"
}
</script>

<style scoped>

</style>
