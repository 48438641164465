import * as Data from '@/services/data/data'
import http from "@/http";

function getGeneralInformation() {
    return http.get('/information')
}

function getDossiers() {
    return http.get('/dossiers')
}

function getArchivedDossiers() {
    return http.get('/archived')
}

function getDossierDetails(id) {
    return http.get(`/dossiers/${id}`)
}

function getActions() {
    return Promise.resolve({
        data: Data.ACTIONS
    })
}

function getTemplates(){
    return http.get('/templates')
}

function getResults() {
    return Promise.resolve({
        data: Data.RESULTS
    })
}

function getTypes() {
    return Promise.resolve({
        data: Data.TYPES
    })
}

function getActionDetail(id) {
    return http.get(`/actions/${id}`)
}

function getActionRemark(id) {
    return http.get(`'/actions/${id}/remark`)
}


function downloadFile(url) {
    return http.get(url)
}

function storeFile(data, id) {
    return http.post(`/dossiers/${id}/files`, data)
}

function postRemarkToAction(remark, action_id) {
    return http.post(`/actions/${action_id}/remark`, {
        remark: remark
    })
}

function deleteRemark(id){
    return http.post(`/remarks/${id}`)
}

function updateActionDeadline(deadline, id) {
    return http.post(`/actions/${id}/update`, {
        date: deadline
    })
}

/**
 * @param id: Integer
 * @return {Promise<AxiosResponse<any>>}
 */
function getInspectionDetails(id){
    return http.get(`/inspections/${id}`)
}


/**
 * @param data formData
 * @param id :Integer ID of Dossier
 * @return {Promise<AxiosResponse<any>>}
 */
function postLogbookItem(data, id) {
    return http.post(`/dossiers/${id}/action`, data)
}

function logout(){
return http.post('/logout')
}

export {
    getGeneralInformation,
    getDossiers,
    getActions,
    getResults,
    getTypes,
    postLogbookItem,
    getDossierDetails,
    getActionDetail,
    getActionRemark,
    downloadFile,
    storeFile,
    postRemarkToAction,
    getArchivedDossiers,
    updateActionDeadline,
    getTemplates,
    getInspectionDetails,
    logout,
    deleteRemark

}
